import {
  GET_PREORDERS,
  GET_PREORDERS_SUCCESS,
  GET_PREORDERS_FAIL,
  GET_PREORDER_DETAILS,
  GET_PREORDER_DETAILS_SUCCESS,
  GET_PREORDER_DETAILS_FAIL,
  CREATE_PREORDER,
  CREATE_PREORDER_SUCCESS,
  CREATE_PREORDER_FAIL,
  UPDATE_PREORDER,
  UPDATE_PREORDER_SUCCESS,
  UPDATE_PREORDER_FAIL,
  DELETE_PREORDER,
  DELETE_PREORDER_SUCCESS,
  DELETE_PREORDER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  preorders: {
    preorders: [],
    total: "",
    page: "",
  },
  preorderDetails: {},
  error: {},
  loading: false,
}

const PreOrder = (state = INIT_STATE, action) => {
  switch (action.type) {
    // PreOrder - This line cannot be edited or removed
    case GET_PREORDERS:
    case GET_PREORDER_DETAILS:
    case CREATE_PREORDER:
    case UPDATE_PREORDER:
    case DELETE_PREORDER:
      return {
        ...state,
        loading: true,
      }

    case DELETE_PREORDER_FAIL:
    case GET_PREORDERS_FAIL:
    case GET_PREORDER_DETAILS_FAIL:
    case UPDATE_PREORDER_FAIL:
    case CREATE_PREORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PREORDERS_SUCCESS:
      return {
        ...state,
        preorders: action.payload,
        error: {},
        loading: false,
      }

    case GET_PREORDER_DETAILS_SUCCESS:
      return {
        ...state,
        preorderDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_PREORDER_SUCCESS:
      return {
        ...state,
        preorders: {
          ...state.preorders,
          preorders: [...state.preorders.preorders, action.payload],
          total: state.preorders.total + 1,
        },
        preorderDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_PREORDER_SUCCESS:
      return {
        ...state,
        preorders: {
          ...state.preorders,
          preorders: state.preorders.preorders.map(preorder =>
            preorder._id === action.payload._id
              ? { ...preorder, ...action.payload }
              : preorder
          ),
        },
        preorderDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_PREORDER_SUCCESS:
      return {
        ...state,
        preorders: {
          ...state.preorders,
          preorders: state.preorders.preorders.filter(
            preorder => preorder._id !== action.payload._id
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default PreOrder
