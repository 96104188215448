/* Complaints - This line cannot be edited or removed */
export const GET_COMPLAINTS = "GET_COMPLAINTS"
export const GET_COMPLAINTS_SUCCESS = "GET_COMPLAINTS_SUCCESS"
export const GET_COMPLAINTS_FAIL = "GET_COMPLAINTS_FAIL"

/* Complaint_DETAILS */
export const GET_COMPLAINT_DETAILS = "GET_COMPLAINT_DETAILS"
export const GET_COMPLAINT_DETAILS_SUCCESS = "GET_COMPLAINT_DETAILS_SUCCESS"
export const GET_COMPLAINT_DETAILS_FAIL = "GET_COMPLAINT_DETAILS_FAIL"

/* CREATE Complaint */
export const CREATE_COMPLAINT = "CREATE_COMPLAINT"
export const CREATE_COMPLAINT_SUCCESS = "CREATE_COMPLAINT_SUCCESS"
export const CREATE_COMPLAINT_FAIL = "CREATE_COMPLAINT_FAIL"

/* Edit Complaint */
export const UPDATE_COMPLAINT = "UPDATE_COMPLAINT"
export const UPDATE_COMPLAINT_SUCCESS = "UPDATE_COMPLAINT_SUCCESS"
export const UPDATE_COMPLAINT_FAIL = "UPDATE_COMPLAINT_FAIL"

/* Delete Complaint */
export const DELETE_COMPLAINT = "DELETE_COMPLAINT"
export const DELETE_COMPLAINT_SUCCESS = "DELETE_COMPLAINT_SUCCESS"
export const DELETE_COMPLAINT_FAIL = "DELETE_COMPLAINT_FAIL"
