import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  transactions: {
    transactions: [],
    total: "",
    page: "",
  },
}

const Transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    // Transaction - This line cannot be edited or removed
    case GET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      }

    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default Transaction
