import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import { createBid, getAllProducts, updateBid } from "store/actions"
import Select from "react-select"

const PreOrderModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { prodLoading, products } = useSelector(state => ({
    prodLoading: state.Products.loading,
    products: state.Products.products,
  }))

  const [productSelect, setProductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()
  const [select, setSelect] = useState("")

  const handleValidSubmit = (e, v) => {
    const data = {
      ...v,
      amount: parseInt(v?.amount),
      product: productSelect?.id,
    }
    const updateDatas = {
      ...v,
      amount: parseInt(v?.amount),
      product: productSelect?.id,
      status: select,
    }
    if (updateData?._id) {
      console.log(data)
      dispatch(updateBid(updateDatas, updateData?._id))
      onCloseClick()
    } else {
      dispatch(createBid(data))
      onCloseClick()
    }
  }
  console.log(updateData)
  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  const handleSelectChange = event => {
    const select = event.target.value
    setSelect(select)
  }
  useEffect(() => {
    setSelect(updateData?.status)
  }, [updateData])
  //product dropdown
  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }
  useEffect(() => {
    if (updateData?._id) {
      setProductSelect({
        title: updateData?.product?.primaryLang?.name,
        value: updateData?.product?._id,
      })
    }
  }, [updateData])
  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    })
  )
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle className="mb-2">
          {updateData ? "Edit" : "Add"} Bid
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col lg={12} className="mt-3">
              <p className="h4 mb-2 text-muted font-size-14">Products</p>
              <FormGroup className="mb-3">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleProductEnters}
                    value={productSelect?.title}
                    placeholder={productSelect?.title}
                    onChange={handlerProductFinalValue}
                    options={productOptions}
                    classNamePrefix="select2-selection"
                    isLoading={prodLoading}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col lg={12} className="">
              <AvField
                name="amount"
                type="number"
                placeholder="Enter Amount"
                value={updateData?.amount}
              />
            </Col>
            {updateData && (
              <>
                <Col lg={12} className="margin">
                  <AvField
                    name="remark"
                    type="text"
                    placeholder="Enter Remark"
                  />
                </Col>

                <Col md="4" lg="12">
                  <label className="pt-1 mt-1" htmlFor="selectField">
                    Status
                  </label>
                  <select
                    id="selectField"
                    className="form-control"
                    value={select}
                    onChange={handleSelectChange}
                  >
                    <option value="pending"> Pending</option>
                    <option value="approved">Approved</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </Col>
              </>
            )}
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

PreOrderModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default PreOrderModal
