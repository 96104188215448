/* PreOrders - This line cannot be edited or removed */
export const GET_PREORDERS = "GET_PREORDERS"
export const GET_PREORDERS_SUCCESS = "GET_PREORDERS_SUCCESS"
export const GET_PREORDERS_FAIL = "GET_PREORDERS_FAIL"

/* PreOrder_DETAILS */
export const GET_PREORDER_DETAILS = "GET_PREORDER_DETAILS"
export const GET_PREORDER_DETAILS_SUCCESS = "GET_PREORDER_DETAILS_SUCCESS"
export const GET_PREORDER_DETAILS_FAIL = "GET_PREORDER_DETAILS_FAIL"

/* CREATE PreOrder */
export const CREATE_PREORDER = "CREATE_PREORDER"
export const CREATE_PREORDER_SUCCESS = "CREATE_PREORDER_SUCCESS"
export const CREATE_PREORDER_FAIL = "CREATE_PREORDER_FAIL"

/* Edit PreOrder */
export const UPDATE_PREORDER = "UPDATE_PREORDER"
export const UPDATE_PREORDER_SUCCESS = "UPDATE_PREORDER_SUCCESS"
export const UPDATE_PREORDER_FAIL = "UPDATE_PREORDER_FAIL"

/* Delete PreOrder */
export const DELETE_PREORDER = "DELETE_PREORDER"
export const DELETE_PREORDER_SUCCESS = "DELETE_PREORDER_SUCCESS"
export const DELETE_PREORDER_FAIL = "DELETE_PREORDER_FAIL"
