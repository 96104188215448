import React, { useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import useCloudinary from "hooks/useCloudinaryImage"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"
import PropTypes from "prop-types"

//actions
import { deleteProduct, getProductSortCount } from "store/products/actions"

import "../../assets/scss/datatables.scss"

import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import { getBidDetails, getBidProducts } from "store/actions"
import ProductBidsTable from "./ProductBidsTable"

const BidProducts = ({ setSingleProduct, singleProduct }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { productData, total, bidProductDetails } = useSelector(state => ({
    productData: state?.Bid?.products,
    total: state?.Bid?.total,
    bidProductDetails: state.Bid.bidProductDetails?.bids,
  }))

  const products = productData?.products
  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)

  const [searchText, setSearchText] = useState("")

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteProduct(isOpen?.id, history))
  }
  const handleSingleProduct = item => {
    setSingleProduct(item)
    dispatch(getBidDetails(item?._id, history))
  }
  // console.log(setSingleProduct)

  useEffect(() => {
    dispatch(getBidProducts(page, "", totalItemLimit, ""))
  }, [dispatch, page, searchText, totalItemLimit])

  useEffect(() => {
    dispatch(getProductSortCount())
  }, [dispatch])

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "itemCode",
      filterText: "itemCode",
      text: "Item Code",
      sort: true,
    },
    {
      dataField: "nameData",
      filterText: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "imageData",
      filterText: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "bidsCount",
      filterText: "bidsCount",
      text: "Bids",
      sort: true,
    },
    {
      dataField: "bidAmount",
      filterText: "bidsCount",
      text: "Bids Amount",
      sort: true,
    },
    {
      dataField: "price",
      text: "Product Price",
      sort: true,
    },
  ]

  //all
  const allProductData = map(products, (item, index) => ({
    ...item,
    key: item._id,
    itemCode: (
      <span
        style={{ cursor: "pointer" }}
        className="text-info"
        onClick={() => {
          handleSingleProduct(item)
        }}
      >
        {item?.itemCode}
      </span>
    ),
    price: <span>₹ {item?.offerPrice ? item.offerPrice : item?.price}</span>,
    nameData: (
      <Row>
        {item?.primaryLang?.name.length > 40
          ? item?.primaryLang?.name.substr(0, 40 - 1) + "..."
          : item?.primaryLang?.name}
      </Row>
    ),

    imageData: (
      <>
        <img
          width="50px"
          src={
            item?.images && item?.images[0]
              ? useCloudinary(item?.images[0]?.url, "w_900")
              : defaultImg
          }
        />
      </>
    ),
  }))

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col sm={bidProductDetails && singleProduct ? 6 : 12}>
          <Card>
            <CardBody>
              <Row>
                <Col sm={6} className="d-flex align-items-start">
                  <div className="search-box ms-2 d-inline-block">
                    <div className="position-relative">
                      <AvForm>
                        <AvField
                          name="searchText"
                          placeholder="Search"
                          type="text"
                          value={searchText}
                          onChange={e => debounceProductSearch(e.target.value)}
                        />
                      </AvForm>
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                  <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                    <div className="mx-1">
                      <span className="">
                        Show
                        <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                      </span>
                    </div>
                    <div className="" style={{ width: "50px" }}>
                      <AvField
                        name="pageLimit"
                        className="form-control"
                        value={totalItemLimit}
                        onChange={e => setTotalItemLimit(e)}
                        type="text"
                        required
                      />
                    </div>
                  </AvForm>
                </Col>
                <Col sm={6} className="d-flex justify-content-end">
                  <div className="d-flex align-items-start justify-content-between p-1">
                    <div className="">
                      {" "}
                      <Link to="product/create/new">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                        >
                          <i className="mdi mdi-plus me-1" />
                          Create Product
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={allProductData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <>
                        {products?.length >= 0 ? (
                          <>
                            <Row>
                              <p>Total Products :&nbsp;{productData?.total} </p>

                              <Col lg={12}>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No Products !</p>
                        )}
                      </>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
        {bidProductDetails && singleProduct && (
          <Col className="col-6">
            <Card>
              <CardBody>
                <ProductBidsTable singleProduct={singleProduct} />
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}

export default BidProducts
BidProducts.propTypes = {
  singleProduct: PropTypes.any,
  setSingleProduct: PropTypes.any,
}
