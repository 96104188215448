import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Media,
  Badge,
} from "reactstrap"
import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import useCloudinary from "hooks/useCloudinaryImage"

//actions
import {
  deleteUser,
  getbidProductDetails,
  getUserCarts,
  getUserCartDetails,
  getUserFavouriteDetails,
} from "store/users/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import MetaTag from "components/Common/Meta-tag"
import { getBidDetails } from "store/actions"
// import ShopProductDetails from ".";

const BidProductDetails = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const [activeTab, setActiveTab] = useState(0)

  const { bidProductDetails } = useSelector(state => ({
    bidProductDetails: state.Bid.bidProductDetails?.bids,
    userVarients: state.Users.userVarients,
    loading: state.Users.loading,
  }))
  console.log(bidProductDetails)
  const { userCartDetails } = useSelector(state => ({
    userCartDetails: state.Users.userCartDetails,
  }))

  const { userFavouriteDetails } = useSelector(state => ({
    userFavouriteDetails: state.Users.userFavouriteDetails,
  }))

  const handleDelete = () => {
    dispatch(deleteUser(bidProductDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getUserCarts())
  }, [dispatch])

  useEffect(() => {
    dispatch(getBidDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserCartDetails(params.id))
  }, [dispatch])

  useEffect(() => {
    dispatch(getUserFavouriteDetails(params.id))
  }, [dispatch])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const imageShow = (img, id) => {
    const expandImg = {}
    expandImg.src = document.getElementById("expandedImg")
  }

  const userRole = usrRole => {}

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="User Details" />

        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="Bid Product Details" />
          {!isEmpty(bidProductDetails) && (
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="10">
                          <Media>
                            <Media className="align-self-center" body>
                              <div className="text-muted">
                                <h5 className="mb-1">
                                  {bidProductDetails?.name}
                                </h5>
                                <Badge
                                  className={"font-size-12 badge-soft-success"}
                                  color=""
                                  pill
                                >
                                  {bidProductDetails?.role}
                                </Badge>
                                <p className="mb-0">
                                  {bidProductDetails?.email}
                                </p>
                                <p className="mb-0">
                                  UserID: {bidProductDetails?.firebaseUid}
                                </p>
                              </div>
                            </Media>
                          </Media>
                        </Col>

                        <Col lg="2" className="d-none d-lg-block">
                          <div className="clearfix mt-4 mt-lg-0">
                            <Link to={`/user/update/${bidProductDetails?._id}`}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                              >
                                <i className="bx bx-edit me-2" /> Update
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              color="warning"
                              className="ms-1 btn mt-2"
                              onClick={() => {
                                handleDelete(bidProductDetails?.firebaseUid)
                              }}
                            >
                              <i className="bx bx-user-x me-2" />
                              Block
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>Cart Items:</Label>

                      <Row className="px-4">
                        {userCartDetails?.cartItems?.map((item, key) => (
                          <>
                            <Col key={key} className="col-4">
                              <Card>
                                <CardBody>
                                  <Row className="align-items-center">
                                    <Col md="6">
                                      <img
                                        src={useCloudinary(
                                          item?.shopProduct?.zestonProduct
                                            ?.images[0]?.url,
                                          "w_1000"
                                        )}
                                        alt="user-product"
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <div className="text-center text-md-start pt-3 pt-md-0">
                                        <h5 className="text-truncate">
                                          <Link
                                            to="#"
                                            className="text-dark"
                                            title={
                                              item?.shopProduct?.zestonProduct
                                                ?.name
                                            }
                                          >
                                            {
                                              item?.shopProduct?.zestonProduct
                                                ?.name
                                            }
                                          </Link>
                                        </h5>

                                        <h5 className="my-0">
                                          <p>Size: {item?.size}</p>
                                          <p>Qty: {item?.quantity}</p>
                                          <b>₹ {item?.price}</b>
                                        </h5>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>

                      {/* <Row>
                        <Col>
                          <hr />
                          <Col className="text-end">Total:  ₹ {}</Col>
                        </Col>
                      </Row> */}
                    </CardBody>
                  </Card>
                </Col>
              </Row>{" "}
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>Favourite Items:</Label>

                      <Row className="px-4">
                        {userFavouriteDetails?.data?.map((item, key) => (
                          <>
                            <Col key={key} className="col-4">
                              <Card>
                                <CardBody>
                                  <Row className="align-items-center">
                                    <Col md="6">
                                      <img
                                        src={useCloudinary(
                                          item?.shopProduct?.zestonProduct
                                            ?.images[0].url,
                                          "w_1000"
                                        )}
                                        alt="user-product"
                                        className="img-fluid mx-auto d-block"
                                      />
                                    </Col>
                                    <Col md="6">
                                      <div className="text-center text-md-start pt-3 pt-md-0">
                                        <h5 className="text-truncate">
                                          <Link
                                            to="#"
                                            className="text-dark"
                                            title={
                                              item?.shopProduct?.zestonProduct
                                                ?.name
                                            }
                                          >
                                            {
                                              item?.shopProduct?.zestonProduct
                                                ?.name
                                            }
                                          </Link>
                                        </h5>

                                        <h5 className="my-0">
                                          <p>
                                            Size:{" "}
                                            {item?.shopProduct?.sizes?.map(
                                              size => size?.value
                                            )}
                                          </p>
                                          <p>
                                            ZP : ₹{" "}
                                            {item?.shopProduct?.sizes?.map(
                                              p => p.zestonPrice
                                            )}
                                          </p>
                                          <p>
                                            RP : ₹{" "}
                                            {item?.shopProduct?.sizes?.map(
                                              p => p.retailPrice
                                            )}
                                          </p>
                                        </h5>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>{" "}
            </div>
          )}
          {/* <RecentProducts recentProducts={product.recentProducts} /> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

BidProductDetails.propTypes = {
  user: PropTypes.object,
  match: PropTypes.any,
  onGetUserDetail: PropTypes.func,
}

export default BidProductDetails
