/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* RAW USERS */
export const GET_RAW_USERS = "GET_RAW_USERS"
export const GET_RAW_USERS_SUCCESS = "GET_RAW_USERS_SUCCESS"
export const GET_RAW_USERS_FAIL = "GET_RAW_USERS_FAIL"

/* USER_DETAILS */
export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL"

/* Edit USER*/
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"

export const CLEAR_USER_DATA = "CLEAR_USER_DATA"

/* Edit USER*/
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/* Delete USER*/
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

// Cart Items

/* CARTS */
export const GET_USER_CARTS = "GET_USER_CARTS"
export const GET_USER_CARTS_SUCCESS = "GET_USER_CARTS_SUCCESS"
export const GET_USER_CARTS_FAIL = "GET_USER_CARTS_FAIL"

/* CART_DETAILS */
export const GET_USER_CART_DETAILS = "GET_USER_CART_DETAILS"
export const GET_USER_CART_DETAILS_SUCCESS = "GET_USER_CART_DETAILS_SUCCESS"
export const GET_USER_CART_DETAILS_FAIL = "GET_USER_CART_DETAILS_FAIL"

/* Delete CART*/
export const DELETE_USER_CART = "DELETE_USER_CART"
export const DELETE_USER_CART_SUCCESS = "DELETE_USER_CART_SUCCESS"
export const DELETE_USER_CART_FAIL = "DELETE_USER_CART_FAIL"

/* FAVOURITE_DETAILS */
export const GET_USER_FAVOURITE_DETAILS = "GET_USER_FAVOURITE_DETAILS"
export const GET_USER_FAVOURITE_DETAILS_SUCCESS =
  "GET_USER_FAVOURITE_DETAILS_SUCCESS"
export const GET_USER_FAVOURITE_DETAILS_FAIL = "GET_USER_FAVOURITE_DETAILS_FAIL"

/* RECENT_VIEWS */
export const GET_USER_RECENT_VIEWS = "GET_USER_RECENT_VIEWS"
export const GET_USER_RECENT_VIEWS_SUCCESS = "GET_USER_RECENT_VIEWS_SUCCESS"
export const GET_USER_RECENT_VIEWS_FAIL = "GET_USER_RECENT_VIEWS_FAIL"

/* Delete FAVOURITE*/
export const DELETE_USER_FAVOURITE = "DELETE_USER_FAVOURITE"
export const DELETE_USER_FAVOURITE_SUCCESS = "DELETE_USER_FAVOURITE_SUCCESS"
export const DELETE_USER_FAVOURITE_FAIL = "DELETE_USER_FAVOURITE_FAIL"

//user address by admin
export const GET_USER_ADDRESS = "GET_USER_ADDRESS"
export const GET_USER_ADDRESS_SUCCESS = "GET_USER_ADDRESS_SUCCESS"
export const GET_USER_ADDRESS_FAIL = "GET_USER_ADDRESS_FAIL"

export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS"
export const ADD_USER_ADDRESS_SUCCESS = "ADD_USER_ADDRESS_SUCCESS"
export const ADD_USER_ADDRESS_FAIL = "ADD_USER_ADDRESS_FAIL"

export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS"
export const DELETE_USER_ADDRESS_SUCCESS = "DELETE_USER_ADDRESS_SUCCESS"
export const DELETE_USER_ADDRESS_FAIL = "DELETE_USER_ADDRESS_FAIL"

// add item to user cart
export const ADD_ITEM_TO_USER_CART = "ADD_ITEM_TO_USER_CART"
export const ADD_ITEM_TO_USER_CART_SUCCESS = "ADD_ITEM_TO_USER_CART_SUCCESS"
export const ADD_ITEM_TO_USER_CART_FAIL = "ADD_ITEM_TO_USER_CART_FAIL"

export const ADD_COUPON_TO_USER_CART = "ADD_COUPON_TO_USER_CART"
export const ADD_COUPON_TO_USER_CART_SUCCESS = "ADD_COUPON_TO_USER_CART_SUCCESS"
export const ADD_COUPON_TO_USER_CART_FAIL = "ADD_COUPON_TO_USER_CART_FAIL"

//promoters
/* PROMOTERS */

export const GET_PROMOTERS = "GET_PROMOTERS"
export const GET_PROMOTERS_SUCCESS = "GET_PROMOTERS_SUCCESS"
export const GET_PROMOTERS_FAIL = "GET_PROMOTERS_FAIL"

/* PROMOTER_DETAILS */
export const GET_PROMOTER_DETAILS = "GET_PROMOTER_DETAILS"
export const GET_PROMOTER_DETAILS_SUCCESS = "GET_PROMOTER_DETAILS_SUCCESS"
export const GET_PROMOTER_DETAILS_FAIL = "GET_PROMOTER_DETAILS_FAIL"

/* new PROMOTERS */
export const CREATE_PROMOTER = "CREATE_PROMOTER"
export const CREATE_PROMOTER_SUCCESS = "CREATE_PROMOTER_SUCCESS"
export const CREATE_PROMOTER_FAIL = "CREATE_PROMOTER_FAIL"

/* Edit PROMOTER*/
export const UPDATE_PROMOTER = "UPDATE_PROMOTER"
export const UPDATE_PROMOTER_SUCCESS = "UPDATE_PROMOTER_SUCCESS"
export const UPDATE_PROMOTER_FAIL = "UPDATE_PROMOTER_FAIL"

/* Delete PROMOTER*/
export const DELETE_PROMOTER = "DELETE_PROMOTER"
export const DELETE_PROMOTER_SUCCESS = "DELETE_PROMOTER_SUCCESS"
export const DELETE_PROMOTER_FAIL = "DELETE_PROMOTER_FAIL"

/* check promoter id PROMOTER*/
export const CHECK_PROMOTERID = "CHECK_PROMOTERID"
export const CHECK_PROMOTERID_SUCCESS = "CHECK_PROMOTERID_SUCCESS"
export const CHECK_PROMOTERID_FAIL = "CHECK_PROMOTERID_FAIL"

// pageview Details
export const GET_PAGE_VIEW_DETAILS = "GET_PAGE_VIEW_DETAILS"
export const GET_PAGE_VIEW_DETAILS_SUCCESS = "GET_PAGE_VIEW_DETAILS_SUCCESS"
export const GET_PAGE_VIEW_DETAILS_FAIL = "GET_PAGE_VIEW_DETAILS_FAIL"

// Earning

export const GET_EARINIG_DETAILS = "GET_EARINIG_DETAILS"
export const GET_EARINIG_DETAILS_SUCCESS = "GET_EARINIG_DETAILS_SUCCESS"
export const GET_EARINIG_DETAILS_FAIL = "GET_EARINIG_DETAILS_FAIL"

// All page view

export const GET_PAGE_VIEW = "GET_PAGE_VIEW"
export const GET_PAGE_VIEW_SUCCESS = "GET_PAGE_VIEW_SUCCESS"
export const GET_PAGE_VIEW_FAIL = "GET_PAGE_VIEW_FAIL"

// All Earnings

export const GET_EARNING = "GET_EARNING"
export const GET_EARNING_SUCCESS = "GET_EARNING_SUCCESS"
export const GET_EARNING_FAIL = "GET_EARNING_FAIL"

// All Formatted Earnings

export const GET_ALL_FORMATTED_EARNINGS = "GET_ALL_FORMATTED_EARNINGS"
export const GET_ALL_FORMATTED_EARNINGS_SUCCESS =
  "GET_ALL_FORMATTED_EARNINGS_SUCCESS"
export const GET_ALL_FORMATTED_EARNINGS_FAIL = "GET_ALL_FORMATTED_EARNINGS_FAIL"

// user approve
export const APPROVE_USER = "APPROVE_USER"
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS"
export const APPROVE_USER_FAIL = "APPROVE_USER_FAIL"
