import React, { Fragment, useEffect, useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Row, Col, Card, CardBody, Button, Badge, FormGroup } from "reactstrap"
import { Spinner, UncontrolledTooltip } from "reactstrap"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import { debounce, map, range } from "lodash"
import useCloudinary from "hooks/useCloudinaryImage"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions
import {
  getAllProducts,
  createDuplicateProduct,
  deleteProduct,
  hardDeleteProduct,
  restoreProduct,
  changeProductStatus,
  undoRefrash,
  getProductSortCount,
} from "store/products/actions"

import "../../assets/scss/datatables.scss"

import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"

const Products = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const {
    count,
    productData,
    total,
    loading,
    productInputs,
    StoreLoading,
    stores,
    needRefrash,
  } = useSelector(state => ({
    productData: state.Products,
    total: state.Products.total,
    loading: state.Products.loading,
    productInputs: state.Settings.settings.setup?.productInputs,
    // stores: state.Stores.stores?.stores,
    StoreLoading: state.Stores.loading,
    needRefrash: state.Products.needRefrash,
    count: state?.Products?.productSortCount,
  }))

  const products = productData?.products
  console.log(total)
  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [page, setPage] = useState(1)
  // const [searchText, setSearchText] = useState("")
  // const [sort, setSort] = useState("all")

  // const [storeSearchText, setStoreSearchText] = useState("")
  // const [selectedStore, setSelectedStore] = useState("All Store")
  // const [finalStoreId, setFinalStoreId] = useState()

  const [searchText, setSearchText] = useState("")
  const [sort, setSort] = useState("all")
  const [storeSearchText, setStoreSearchText] = useState("")
  const [selectedStore, setSelectedStore] = useState("All Store")
  const [finalStoreId, setFinalStoreId] = useState("")

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteProduct(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }

  const handleCopyProduct = id => {
    dispatch(createDuplicateProduct(id, history))
  }

  const handleHardDelete = id => {
    dispatch(hardDeleteProduct(id, history))
  }

  const handleRestore = id => {
    dispatch(restoreProduct(id, history))
  }

  const handleSwitchChange = item => {
    dispatch(changeProductStatus(item._id, item?.isActive))
  }

  useEffect(() => {
    dispatch(
      getAllProducts(page, sort, totalItemLimit, searchText, finalStoreId)
    )
    if (needRefrash === true) {
      dispatch(
        getAllProducts(page, sort, totalItemLimit, searchText, finalStoreId)
      )
    }
  }, [
    dispatch,
    page,
    sort,
    searchText,
    totalItemLimit,
    finalStoreId,
    needRefrash,
  ])

  useEffect(() => {
    dispatch(getProductSortCount())
  }, [dispatch])

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  const columns = [
    {
      dataField: "itemCode",
      filterText: "itemCode",
      text: "Item Code",
      sort: true,
    },
    {
      dataField: "nameData",
      filterText: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "imageData",
      filterText: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "categoryData",
      filterText: "categories",
      text: "Categories",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    // {
    //   dataField: "stock",
    //   text: "Stock",
    //   sort: true,
    // },

    {
      dataField: "activeData",
      filterText: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const inputs = productInputs?.map(i => i.name)

  const modifiedColumns = columns?.filter(col =>
    inputs?.includes(col?.filterText)
  )

  const finalCol = [
    ...columns,
    {
      dataField: "action",
      text: "Actions",
    },
  ]

  const colors = ["danger", "success", "info"]
  console.log(products)
  //all
  const allProductData = map(products, (item, index) => ({
    ...item,
    key: item._id,
    itemCode: (
      <Link to={`/product/update/${item?._id}`}>
        {" "}
        <span>{item?.itemCode}</span>
      </Link>
    ),
    stock: (
      <span className={item?.stock <= 0 ? "text-danger" : ""}>
        {item?.stock}
      </span>
    ),
    price: <span>₹ {item?.offerPrice ? item.offerPrice : item?.price}</span>,
    nameData: (
      // <Row >
      //   {item?.primaryLang?.name.length > 40
      //     ? item?.primaryLang?.name.substr(0, 40 - 1) + "..."
      //     : item?.primaryLang?.name}
      // </Row>
      <Row>
        <div
          className={`text-truncate ${
            item?.primaryLang?.name.length > 30
              ? "d-inline-block text-wrap"
              : ""
          }`}
          style={{ maxWidth: "200px" }}
        >
          {item?.primaryLang?.name}
        </div>
      </Row>
    ),

    categoryData: (
      <div className="d-flex flex-wrap">
        {item?.categories?.map((category, index) => {
          // console.log(item)
          return (
            <Fragment key={index}>
              <Badge
                className={
                  "mx-1 font-size-12 badge-soft-info mt-1"
                  // `${colors[Math.floor(Math.random() * colors.length)]}`
                }
                color="black"
                pill
              >
                {category?.primaryLang?.name}
              </Badge>
            </Fragment>
          )
        })}
      </div>
    ),

    imageData: (
      <>
        <img
          // src={
          //   item?.images && item?.images[0] ? item?.images[0]?.url : defaultImg
          // }
          width="50px"
          src={
            item?.images && item?.images[0]
              ? useCloudinary(item?.images[0]?.url, "w_900")
              : defaultImg
          }
        />
      </>
    ),

    colorData: (
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <Col
          md="2"
          className={"avatar-title rounded-circle bg-soft"}
          style={{
            backgroundColor: `${item?.color?.value}`,
            width: "30px",
            height: "30px",
            display: item?.color?.value == "Not Specified" ? "none" : "",
            boxShadow: "0px 0px 5px #888888",
          }}
        ></Col>
        <Col>{item?.color?.name}</Col>
      </Row>
    ),
    activeData: (
      <>
        <div className="form-check form-switch form-switch-md mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitchsizemd"
            onChange={() => {
              handleSwitchChange(item)
            }}
            // checked={item?.isActive}
            checked={item?.isActive === true}
          />
          <label className="form-check-label" htmlFor="customSwitchsizemd">
            {item?.isActive ? `Active` : `Inactive`}
          </label>
        </div>
      </>
    ),
    action: (
      <div>
        <Link to={`/product/update/${item?._id}`}>
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
          >
            <i
              className="bx bx-edit me-2"
              style={{ color: "blue", fontSize: "22px" }}
            />
          </Button>
        </Link>

        {sort == "deleted" ? (
          <>
            <Button
              id={`restore${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleRestore(item._id)
              }}
            >
              <i
                className="bx bx-revision me-2"
                style={{ color: "green", fontSize: "20px" }}
              />
            </Button>
            <Button
              id={`hardDelete${index}`}
              type="button"
              color="white"
              className="ms-1 btn"
              onClick={() => {
                handleHardDelete(item._id)
              }}
            >
              <i
                className="bx bx-error-alt me-2"
                style={{ color: "red", fontSize: "20px" }}
              />
            </Button>
          </>
        ) : (
          <>
            <Button
              id={`copy${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleCopyProduct(item._id)
              }}
            >
              <i className="bx bx-copy me-2" style={{ fontSize: "20px" }} />
            </Button>
            <Button
              id={`Delete${index}`}
              type="button"
              color="white"
              className="ms-1 btn "
              onClick={() => {
                handleDelete(item._id)
              }}
            >
              <i
                className="bx bx-trash me-2"
                style={{ color: "red", fontSize: "20px" }}
              />
            </Button>
          </>
        )}

        <UncontrolledTooltip placement="bottom" target={`view${index}`}>
          Edit
        </UncontrolledTooltip>

        {sort == "deleted" ? (
          <>
            <UncontrolledTooltip placement="bottom" target={`restore${index}`}>
              Restore
            </UncontrolledTooltip>
            <UncontrolledTooltip
              placement="bottom"
              target={`hardDelete${index}`}
            >
              Hard Delete
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <UncontrolledTooltip placement="bottom" target={`copy${index}`}>
              Duplicate
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="bottom" target={`Delete${index}`}>
              Delete
            </UncontrolledTooltip>
          </>
        )}
      </div>
    ),
  }))

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const listChange = sortValue => {
    setSort(sortValue)
    setPage(1)
    console.log(sortValue)
    history?.push({
      pathname: location.pathname,
      search: `p=${1}&s=${sortValue}`,
      state: {
        page: 1,
        sort: sortValue,
      },
    })
    // dispatch(getAllProducts(page, sortValue, totalItemLimit, ""))
  }
  useEffect(() => {
    if (location?.state && location?.state?.sort) {
      setSort(location?.state?.sort)
    } else {
      // setSort("all")
    }
  }, [location?.state?.sort])

  console.log(sort)
  console.log(location)

  const limitValues = [10, 20, 50, 100]

  useEffect(() => {
    const currentLimit = localStorage.getItem("productLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("productLimit", e.target.value)
    }
  }

  const tableTitles = [
    {
      title: "All",
      value: "all",
      count: count?.allProductsCount || 0,
    },

    {
      title: "Active",
      value: "active",
      count: count?.activeProductsCount || 0,
    },

    {
      title: "Inactive",
      value: "inactive",
      count: count?.inactiveProductsCount || 0,
    },

    {
      title: "Featured",
      value: "featured",
      count: count?.featuresProductsCount || 0,
    },

    // {
    //   title: "Discounted",
    //   value: "discounted",
    //   count: count?.discounted || 0,
    // },

    {
      title: "Deleted",
      value: "deleted",
      count: count?.deletedProductsCount || 0,
    },

    {
      title: "Out of Stock",
      value: "out of stock",
      count: count?.outofstockProductsCount || 0,
    },
  ]

  // const debounceStoreSearch = debounce(value => setStoreSearchText(value), 600)

  // const handleEnters = textEntered => {
  //   debounceStoreSearch(textEntered)
  // }

  // function handlerProductFinalValue(event) {
  //   setSelectedStore(event.label)
  //   setFinalStoreId(event.id)
  // }

  // const storeOptions = [
  //   {
  //     label: "All Store",
  //     id: "",
  //   },
  //   {
  //     options: stores?.map((result, index) => ({
  //       key: index,
  //       label: result?.storeName,
  //       id: result?._id,
  //     })),
  //   },
  // ]

  const debounceProductSearch = debounce(value => setSearchText(value), 600)

  useEffect(() => {
    if (needRefrash === true) {
      setTimeout(() => {
        dispatch(undoRefrash())
      }, 2000)
    }
  }, [needRefrash])

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <Row>
                <div className="d-flex align-items-center justify-content-between p-1">
                  <div
                    className="btn-group mt-2 mt-xl-0"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    {map(tableTitles, (head, key) => (
                      <Fragment key={key}>
                        <input
                          type="radio"
                          className="btn-check"
                          name="btnradio"
                          id={head.value + key}
                          autoComplete="off"
                          checked={sort === head.value}
                          onChange={() => listChange(head?.value || "")}
                        />
                        <label
                          className="btn btn-success d-flex align-items-center w-100"
                          htmlFor={head.value + key}
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {head?.title}

                          <Badge
                            className={`font-size-12 ms-2 ${
                              sort === head.value
                                ? "badge-soft-warning"
                                : "badge-soft-light"
                            }`}
                            pill
                          >
                            {head?.count}
                          </Badge>
                        </label>
                      </Fragment>
                    ))}
                  </div>

                  <div className="mt-3">
                    {" "}
                    <Link to="product/create/new">
                      <Button
                        type="button"
                        color="success"
                        className="btn-rounded  mb-2 me-2"
                      >
                        <i className="mdi mdi-plus me-1" />
                        Create Product
                      </Button>
                    </Link>
                  </div>
                </div>
              </Row>

              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={allProductData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col xl={4} lg={6} md={6} sm={6} className="d-flex ">
                          <div className="search-box ms-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  value={searchText}
                                  onChange={e =>
                                    debounceProductSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                          <AvForm className="form-horizontal mx-3 d-flex align-items-center">
                            <div className="mb-3 mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="mb-3" style={{ width: "50px" }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={totalItemLimit}
                                onChange={e => handlePageLimit(e)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm>
                          {/* <Col lg={2} sm={2} className="mx-2">
                            <AvForm className="form-horizontal">
                              <div className="mb-3" style={{ width: "80px" }}>
                                <AvField
                                  name="pageLimit"
                                  className="form-control"
                                  value={pagination}
                                  min={1}
                                  onChange={e => handlePageLimit(e)}
                                  type="number"
                                  required
                                />
                              </div>
                            </AvForm>
                          </Col> */}
                          {/* {products.length < 10 ? (
                            <div className="mx-4">
                              <select
                                name="pagenation"
                                id="page"
                                style={{
                                  height: "80%",
                                  padding: "0 20px",
                                  borderColor: "#d0d6db",
                                }}
                                className="rounded"
                                onChange={e => handlePageLimit(e)}
                              >
                                {map(limitValues, (item, key) => (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </select>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </Col>
                        {/* <Col xl={4} lg={6} md={6} sm={6} className="">
                          <FormGroup className="w-100 custom_store_select">
                            <div className="mb-3 ajax-select mt-lg-0 select2-container">
                              <Select
                                onInputChange={handleEnters}
                                value={selectedStore}
                                placeholder={selectedStore}
                                onChange={handlerProductFinalValue}
                                options={storeOptions}
                                classNamePrefix="select-selection"
                                isLoading={StoreLoading}
                              />
                            </div>
                          </FormGroup>
                        </Col> */}
                      </Row>
                      {/* {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : ( */}
                      <>
                        {products.length >= 1 ? (
                          <>
                            <Row>
                              <p>Total Products :&nbsp;{productData?.total} </p>

                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    // onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <MyPagination
                              totalPages={pages}
                              page={page}
                              setPage={setPage}
                            />
                          </>
                        ) : (
                          <p className="my-4 mx-3 ">No {sort} Products !</p>
                        )}
                      </>
                      {/* )} */}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Products
