import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import {
  createComplaint,
  getAllProducts,
  getOrders,
  getRawUsers,
  updateComplaint,
} from "store/actions"
import Select from "react-select"

const PreOrderModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { users, usersLoading, prodLoading, products, orders, orderLoading } =
    useSelector(state => ({
      users: state.Users.rawUsers?.users,
      usersLoading: state.Users.loading,
      prodLoading: state.Products.loading,
      products: state.Products.products,
      orders: state?.Orders?.orders,
      orderLoading: state?.Orders?.loading,
    }))

  const [orderSelect, setOrderSelect] = useState("Select order")
  const [orderSelectId, setOrderSelectId] = useState("")
  const [orderSearch, setOrderSearch] = useState("")

  console.log(updateData)

  const [userSearchText, setUserSearchText] = useState("")
  const [selectedUserLabel, setSelectedUserLabel] = useState("Search user")
  const [user, setUser] = useState()
  console.log(user?._id)
  const handleValidSubmit = (e, v) => {
    const data = {
      ...v,
      user,
      order: orderSelectId,
    }

    if (updateData?._id) {
      console.log(data)
      dispatch(updateComplaint(data, updateData?._id))
      onCloseClick()
    } else {
      dispatch(createComplaint(data))
      onCloseClick()
    }
  }
  useEffect(() => {
    dispatch(getRawUsers(userSearchText))
  }, [dispatch, userSearchText])

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedUserLabel(event.label)
    setUser(event?.value)
  }

  useEffect(() => {
    if (updateData) {
      setSelectedUserLabel(updateData?.name)
      setUser(updateData?.user)

      setOrderSelect(updateData?.order?.orderId)
      setOrderSelectId(updateData?.order?._id)
    }
  }, [updateData])

  const userOptions = [
    {
      options:
        users?.length &&
        users?.map((result, index) => ({
          key: index,
          label: `${result?.phone} ${result?.name ? "- " + result?.name : ""}`,
          value: result?._id,
          name: result?.name ? result?.name : "",
          phone: result?.phone,
        })),
    },
  ]

  // order dropdown
  const handleDateSearch = () => {
    return {
      from: "",
      to: "",
    }
  }

  useEffect(() => {
    dispatch(getOrders(1, 10, orderSearch, "all", handleDateSearch()))
  }, [dispatch, orderSearch])

  const debounceOrderSearch = debounce(value => setOrderSearch(value), 600)

  const handleOrderEnters = textEntered => {
    debounceOrderSearch(textEntered)
  }

  function handlerOrderFinalValue(event) {
    setOrderSelect(event.value !== "loading" && event.label)
    setOrderSelectId(event.value)
  }
  const orderOptions = (orders?.length >= 1 ? orders : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.orderId,
      value: result?._id,
    })
  )

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle className="mb-2">
          {updateData ? "Edit" : "Add"} Complaint
        </CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col className="col-12" lg={6}>
              <p className="h4 mb-2 text-muted font-size-14">Select User</p>
              <FormGroup className="col-12">
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleUserEnters}
                    value={selectedUserLabel}
                    placeholder={selectedUserLabel}
                    onChange={handlerUserFinalValue}
                    options={userOptions}
                    classNamePrefix="select2-selection"
                    isLoading={usersLoading}
                  />
                </div>
              </FormGroup>
            </Col>

            {/* <Col lg={6} className="">
              <p className="h4 mb-2 text-muted font-size-14">Products</p>
              <FormGroup className="mb-3">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleProductEnters}
                    value={productSelect?.title}
                    placeholder={productSelect?.title}
                    onChange={handlerProductFinalValue}
                    options={productOptions}
                    classNamePrefix="select2-selection"
                    isLoading={prodLoading}
                  />
                </div>
              </FormGroup>
            </Col> */}
            <Col lg={6} className="">
              <p className="h4 mb-2 text-muted font-size-14">Order</p>
              <FormGroup className="mb-3">
                <div className="ajax-select mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleOrderEnters}
                    value={orderSelect}
                    placeholder={orderSelect}
                    onChange={handlerOrderFinalValue}
                    options={orderOptions}
                    classNamePrefix="select2-selection"
                    isLoading={orderLoading}
                  />
                </div>
              </FormGroup>
            </Col>

            <Col className="col-6 mb-3">
              <AvField
                name="name"
                type="text"
                placeholder="Enter Name"
                value={updateData?.name}
              />
            </Col>

            <Col className="col-6 mb-3">
              <AvField
                name="phone"
                type="number"
                placeholder="Enter Phone"
                value={updateData?.phone}
                validate={{
                  minLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                }}
              />
            </Col>

            <Col lg={12}>
              <AvField
                name="message"
                type="textarea"
                placeholder="Message"
                style={{
                  height: 80,
                }}
                value={updateData?.message}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

PreOrderModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default PreOrderModal
