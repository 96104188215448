import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Product
import Products from "./products/reducer"

//shopProduct
import ShopProducts from "./shopProducts/reducer"

// //store
import Stores from "./stores/reducer"

// //shop
import Shops from "./shops/reducer"

//Category
import Category from "./category/reducer"

//Classifications
import Classifications from "./classifications/reducer"

//user
import Users from "./users/reducer"

//order
import Orders from "./orders/reducer"

// Settings
import Settings from "./settings/reducer"

// Notifications
import Notifications from "./notification/reducer"

// Revenues
import Revenues from "./revenues/reducer"

//Banner
import Banner from "./banner/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

//colletions
import Collection from "./collections/reducer"

//groups
import Group from "./groups/reducer"

//coupon
import Coupon from "./coupon/reducer"

//pages
import Pages from "./productPages/reducer"

//performance
import Performance from "./performance/reducer"

// blog
import Blogs from "./blogs/reducer"

//pre order
import PreOrder from "./preorder/reducer"

//complaint
import Complaint from "./complaint/reducer"
import Bid from "./bid/reducer"
import Transaction from "./transaction/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Products,
  ShopProducts,
  Shops,
  Stores,
  Settings,
  Notifications,
  Revenues,

  Category,
  Banner,
  Classifications,
  Collection,
  Group,
  Pages,
  Coupon,

  Users,
  Orders,
  PreOrder,
  Complaint,
  Dashboard,
  Performance,
  Blogs,
  Bid,
  Transaction,
})

export default rootReducer
