import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

import "../../assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { deleteComplaint, getTransactions } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const TransactionTable = () => {
  const dispatch = useDispatch()

  const { total, loading, transactions } = useSelector(state => ({
    transactions: state?.Transaction?.transactions?.orders,
    total: state?.Transaction?.transactions?.total,
    loading: state?.Complaint?.loading,
  }))
  const [orderSearchText, setOrderSearchText] = useState("")
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  //pagination
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getTransactions(
        page,
        "",
        totalItemLimit,
        orderSearchText,
        finalDateFormated
      )
    )
  }, [dispatch, page, orderSearchText, totalItemLimit, finalDateFormated])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteComplaint(isOpen?.id, ""))
  }

  const columns = [
    {
      dataField: "sl",
      text: "Sl",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "shippingInfo.name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "orderId",
      text: "Order Id",
      sort: true,
    },

    {
      dataField: "paymentType",
      text: "Payment Type",
      sort: true,
    },
    {
      dataField: "shippingInfo.contactNumber",
      text: "Contact Number",
      sort: true,
    },
    // {
    //   dataField: "action",
    //   text: "Action",
    // },
  ]

  console.log(transactions)

  const orderData = map(transactions, (item, index) => ({
    ...item,
    sl: <span>{(page - 1) * 10 + (index + 1)}</span>,
    createdAt: moment(item.createdAt).format("ll"),
    productName: (
      <div className="d-flex align-items-center">
        <img
          // src={item?.product?.images[0]?.url}
          alt="product image"
          className="avatar-sm me-2"
        />
        <p className="p-0 m-0">{item?.product?.primaryLang?.name}</p>
      </div>
    ),
  }))

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem("orderLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("orderLimit", e.target.value)
    }
  }

  const headerOptions = (
    <div
      className={`headerOptionWrap d-flex flex-wrap align-items-center justify-content-between`}
    >
      <div className="search-box ms-2 d-inline-block mb-3">
        <div className="position-relative">
          <AvForm>
            <AvField
              name="searchText"
              placeholder="Search"
              type="text"
              onChange={e => debounceOrderSearch(e.target.value)}
            />
          </AvForm>
          <i className="bx bx-search-alt search-icon" />
        </div>
      </div>
      <div className="d-flex sortWrapper mx-2 me-3 mb-3 align-items-center ">
        <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: "nowrap" }}>
          Sort By Date :
        </p>
        <AvForm>
          <div className="d-flex date_wrapper">
            <div className="d-flex align-items-start position-relative">
              <AvField
                name="dateRange"
                type="date"
                validate={{
                  dateRange: {
                    start: { value: -5, units: "years" },
                    end: { value: 5, units: "years" },
                  },
                }}
                onChange={e =>
                  setFinalDateFormated({
                    ...finalDateFormated,
                    from: e.target.value,
                  })
                }
                value={finalDateFormated?.from}
              />
              <button
                onClick={() =>
                  setFinalDateFormated({
                    ...finalDateFormated,
                    from: "",
                  })
                }
                type="button"
                className="btn btn-white editable-cancel date_close btn-sm"
              >
                <i className="mdi mdi-close text-danger "></i>
              </button>
            </div>
            <div className="d-flex align-items-start position-relative">
              <AvField
                name="dateRange"
                className="mx-1"
                type="date"
                validate={{
                  dateRange: {
                    start: { value: -5, units: "years" },
                    end: { value: 5, units: "years" },
                  },
                }}
                onChange={e =>
                  setFinalDateFormated({
                    ...finalDateFormated,
                    to: e.target.value,
                  })
                }
                value={finalDateFormated?.to}
              />
              <button
                onClick={() =>
                  setFinalDateFormated({
                    ...finalDateFormated,
                    to: "",
                  })
                }
                type="button"
                className="btn btn-white editable-cancel date_close btn-sm"
              >
                <i className="mdi mdi-close text-danger "></i>
              </button>
            </div>
          </div>
        </AvForm>
        {/* <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
            <div className="mx-1">
              <span className="">
                Show
                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
              </span>
            </div>
            <div className="" style={{ width: "50px" }}>
              <AvField
                name="pageLimit"
                className="form-control"
                value={totalItemLimit}
                onChange={e => handlePageLimit(e)}
                type="text"
                required
              />
            </div>
          </AvForm> */}

        {/* <Button
          type="button"
          color="success"
          className="btn-rounded  mb-2 me-2"
          onClick={() => setPreOrderModal({ status: true })}
        >
          <i className="mdi mdi-plus me-1 " /> New Complaint
        </Button> */}
      </div>
    </div>
  )

  return (
    <React.Fragment>
      {/* {preOrderModal?.status && (
        <PreOrderModal
          onCloseClick={() => setPreOrderModal({ status: false, data: {} })}
          show={preOrderModal?.status}
          updateData={preOrderModal?.data}
        />
      )} */}
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card className="pt-3">
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <div className="order-option-table">{headerOptions}</div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {transactions?.length ? (
                          <Row>
                            <p>
                              Total transactions :&nbsp;{total ? total : 0}{" "}
                            </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // keyField={"id"}
                                  key={"_id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap table-striped"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  // rowClasses={"table-info"}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Transactions!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TransactionTable
