import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router"
import { Card, CardBody, Col } from "reactstrap"
import { Container, Label, Row, Media, Badge } from "reactstrap"
import { isEmpty } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//actions
import {
  deleteUser,
  getUserDetails,
  getUserCarts,
  getUserCartDetails,
  getUserFavouriteDetails,
  getUserRecentViews,
} from "store/users/actions"
import { getUserOrderDetails } from "store/orders/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import CartDatatable from "./CartDatatable"
import OrderDatatable from "./OrderDatatable"
import FavDatatable from "./FavDatatable"
import { CurrencySwitch } from "hooks/Currency"
import moment from "moment"
import MetaTag from "components/Common/Meta-tag"
import RecentViewsDatatable from "./RecentViewsDatatable"

const UserDetail = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams()

  const {
    userDetails,
    userCartDetails,
    userFavouriteDetails,
    userRecentViews,
    userOrderDetails,
  } = useSelector(state => ({
    userDetails: state.Users.userDetails,
    userCartDetails: state.Users.userCartDetails,
    userFavouriteDetails: state.Users.userFavouriteDetails,
    userOrderDetails: state.Orders.userOrderDetails,
    userRecentViews: state.Users.userRecentViews,
  }))

  const handleDelete = () => {
    dispatch(deleteUser(userDetails?._id, history))
  }

  useEffect(() => {
    dispatch(getUserCarts())
    dispatch(getUserDetails(params.id))
    dispatch(getUserCartDetails(params.id))
    dispatch(getUserFavouriteDetails(params.id))
    dispatch(getUserRecentViews(params.id))
    dispatch(getUserOrderDetails(params.id))
  }, [dispatch])

  const currency = CurrencySwitch()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="User Details" />

        <Container fluid>
          <Breadcrumbs title="User" breadcrumbItem="User Details" />
          {!isEmpty(userDetails) && (
            <div>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col lg="9">
                          <Media>
                            <Media className="align-self-center" body>
                              <div className="text-muted">
                                <h5 className="mb-1">{userDetails?.name}</h5>
                                <Badge
                                  className={
                                    userDetails.isGuest
                                      ? "font-size-12 badge-soft-primary"
                                      : userDetails.role == "admin"
                                      ? "font-size-12 badge-soft-secondary"
                                      : "font-size-12 badge-soft-success"
                                  }
                                  color=""
                                  pill
                                >
                                  {userDetails?.isGuest
                                    ? "guest"
                                    : userDetails?.role}
                                </Badge>
                                <p className="mb-0 mt-2">
                                  {userDetails?.email}
                                </p>
                                {userDetails?.phone && (
                                  <p className="mb-0 mt-2 d-flex align-items-center">
                                    <i className="bx bx-phone-call text-success font-size-16 mx-1"></i>
                                    {userDetails?.phone}
                                  </p>
                                )}
                                {userDetails?.createdAt && (
                                  <p className="mb-0 mt-2 d-flex align-items-center">
                                    <i className="bx bx-group font-size-16 text-success mx-1"></i>
                                    Joined On&nbsp;
                                    <span className="text">
                                      {" "}
                                      {moment(userDetails?.createdAt).format(
                                        "DD/MM/YYYY - h:mm A"
                                      )}
                                    </span>
                                  </p>
                                )}
                                {userDetails?.lastLogin && (
                                  <p className="mb-0 mt-2 d-flex align-items-center">
                                    <i className="bx bx-log-in-circle text-success font-size-16 mx-1"></i>
                                    Last Login&nbsp;
                                    <span className="text-info">
                                      {" "}
                                      {moment(userDetails?.lastLogin).format(
                                        "DD/MM/YYYY - h:mm A"
                                      )}
                                    </span>
                                  </p>
                                )}
                                {userDetails?.currentCoordinates?.length ===
                                  2 && (
                                  <p className="mb-0 mt-2 d-flex align-items-center">
                                    <i className="bx bxs-map-pin text-success font-size-16 mx-1"></i>
                                    User Location&nbsp;:&nbsp;
                                    <span>
                                      <a
                                        target={"_blank"}
                                        rel="noreferrer"
                                        href={`https://maps.google.com/?q=${userDetails?.currentCoordinates[0]},${userDetails?.currentCoordinates[1]}`}
                                      >
                                        {" "}
                                        View In Map
                                      </a>
                                    </span>
                                  </p>
                                )}
                                <Badge
                                  className={"font-size-12 badge-soft-success"}
                                  pill
                                >
                                  {userDetails?.isGuest && "Guest user"}
                                </Badge>{" "}
                                <Badge
                                  className={"font-size-12 badge-soft-success"}
                                  pill
                                >
                                  {userDetails?.isPrime && "Prime user"}
                                </Badge>
                                {/* <p className="mb-0">
                                  UserID: {userDetails?._id}
                                </p> */}
                              </div>
                            </Media>
                          </Media>
                        </Col>

                        {/* <Col className="d-none d-lg-block col-12 col-sm-12 col-xs-12 col-md-6 col-lg-3 col-xl-3">
                          <div className="clearfix mt-4 mt-lg-0">
                            <Link to={`/user/update/${userDetails?._id}`}>
                              <Button
                                type="button"
                                color="success"
                                className="btn  mt-2 me-1"
                              >
                                <i className="bx bx-edit me-2" /> Update
                              </Button>
                            </Link>
                            <Button
                              type="button"
                              color="warning"
                              className="ms-1 btn mt-2"
                              onClick={() => {
                                handleDelete(userDetails?._id)
                              }}
                            >
                              <i className="bx bx-user-x me-2" />
                              Block
                            </Button>
                          </div>
                        </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>
                        Cart Items:{" "}
                        {userCartDetails?.cartItems?.length ? (
                          userCartDetails?.cartItems?.length
                        ) : (
                          <p className="text-warning mt-1">
                            User does&apos;nt have items in cart
                          </p>
                        )}
                      </Label>
                      {userCartDetails?.cartItems?.length >= 1 && (
                        <>
                          <CartDatatable />
                          <Row>
                            <Col>
                              <hr />
                              <Col className="text-end mb-2">
                                Items Total : {currency}{" "}
                                {userCartDetails?.itemsPrice}
                              </Col>
                              <Col className="text-end mb-2">
                                Shipping : {currency}{" "}
                                {userCartDetails?.shippingPrice}
                              </Col>

                              {/* <Col className="text-end mb-2">
                                TAX: {currency} {userCartDetails?.taxValue}
                              </Col> */}
                              <Col className="text-end mb-2">
                                Discount : {currency}{" "}
                                {userCartDetails?.totalDiscount}
                              </Col>
                              <Col className="text-end mb-2">
                                Gross Total : {currency}{" "}
                                {userCartDetails?.totalPrice}
                              </Col>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>
                        Favourite Items:&nbsp;
                        {userFavouriteDetails?.length >= 1 ? (
                          userFavouriteDetails?.length
                        ) : (
                          <p className="text-warning mt-1">
                            User does not have a Favourite Items yet
                          </p>
                        )}
                      </Label>
                      {userFavouriteDetails?.length >= 1 && <FavDatatable />}{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>
                        Recently Viewed Items:&nbsp;
                        {userRecentViews?.length >= 1 ? (
                          userRecentViews?.length
                        ) : (
                          <p className="text-warning mt-1">
                            User does not have a any recently viwed products!
                          </p>
                        )}
                      </Label>
                      {userRecentViews?.length >= 1 && <RecentViewsDatatable />}{" "}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="col-12">
                    <CardBody>
                      <Label>
                        Orders:{" "}
                        {userOrderDetails?.totalOrders >= 1 ? (
                          userOrderDetails?.totalOrders
                        ) : (
                          <p className="text-warning mt-1">
                            User does not have a Orders yet
                          </p>
                        )}
                      </Label>
                      {userOrderDetails?.totalOrders >= 1 && <OrderDatatable />}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

UserDetail.propTypes = {
  user: PropTypes.object,
  match: PropTypes.any,
  onGetUserDetail: PropTypes.func,
}

export default UserDetail
