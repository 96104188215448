import MetaTag from "components/Common/Meta-tag"
import React from "react"

import BidProductDetails from "./Details"

function BidProductDetailss() {
  return (
    <>
      <MetaTag title={"About user"} />

      <BidProductDetails />
    </>
  )
}

export default BidProductDetailss
