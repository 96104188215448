import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_COMPLAINTS,
  GET_COMPLAINT_DETAILS,
  CREATE_COMPLAINT,
  UPDATE_COMPLAINT,
  DELETE_COMPLAINT,
} from "./actionTypes"
import {
  getComplaintsFail,
  getComplaintsSuccess,
  getComplaintDetailsSuccess,
  getComplaintDetailsFail,
  createComplaintFail,
  createComplaintSuccess,
  updateComplaintSuccess,
  updateComplaintFail,
  deleteComplaintSuccess,
  deleteComplaintFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Complaint - This line cannot be edited or removed
function getComplaintsAPi({ page, sort, limit, searchText }) {
  return get(
    `/complaint/admin/all?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getComplaintDetailsAPi = ComplaintId => {
  return get(`/complaint/admin/${ComplaintId}`)
}

const createComplaintApi = ({ complaint }) => {
  return post("/complaint/admin/new", complaint)
}

const updateComplaintApi = ({ Complaint, ComplaintId }) => {
  return ApiPut(`/complaint/admin/${ComplaintId}`, Complaint)
}

const deleteComplaintApi = complaintId => {
  return del(`/complaint/admin/${complaintId}`)
}

function* fetchComplaints({ payload }) {
  try {
    const response = yield call(getComplaintsAPi, payload)
    yield put(getComplaintsSuccess(response))
  } catch (error) {
    yield put(getComplaintsFail(error))
  }
}

function* fetchComplaintDetails({ payload: ComplaintId }) {
  try {
    const response = yield call(getComplaintDetailsAPi, ComplaintId)
    yield put(getComplaintDetailsSuccess(response))
  } catch (error) {
    yield put(getComplaintDetailsFail(error))
  }
}

// function* onCreateComplaint({ payload }) {
//   try {
//     const response = yield call(createComplaintApi, payload)
//     if (response?.message) {
//       Notification({
//         type: "error",
//         message: response?.message,
//         title: "",
//       })
//     } else if (response) {
//       yield put(createComplaintSuccess(response))
//       payload?.history?.push("/complaints")
//       doneNotification("Complaint Created Successfully!")
//     }
//   } catch (error) {
//     if (error?.response?.data?.message) {
//       Notification({
//         type: "error",
//         message: error?.response?.data?.message,
//         title: "",
//       })
//     }
//     if (error?.response?.status === 413) {
//       Notification({
//         type: "error",
//         message: "file size is Too Large",
//         title: "",
//       })
//     }
//     yield put(createComplaintFail(error))
//   }
// }

function* onCreateComplaint({ payload }) {
  try {
    const response = yield call(createComplaintApi, payload)
    yield put(createComplaintSuccess(response))
    Notification({
      type: "success",
      message: "Complaints Create Successfully!",
      title: "",
    })
    payload.history.push("/complaints")
  } catch (error) {
    yield put(createComplaintFail(error))
  }
}
function* onUpdateComplaint({ payload }) {
  try {
    const response = yield call(updateComplaintApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "Complaint Updated Successfully!",
        title: "",
      })
      yield put(updateComplaintSuccess(response))

      if (payload.history) {
        payload.history.push("/complaints")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateComplaintFail(error.response.data))
  }
}

function* onDeleteComplaint({ payload }) {
  try {
    const response = yield call(deleteComplaintApi, payload?.complaintId)
    yield put(deleteComplaintSuccess(response))
    Notification({
      type: "success",
      message: "Complaint Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteComplaintFail(error?.response?.data))
  }
}

function* complaintSaga() {
  yield takeEvery(GET_COMPLAINTS, fetchComplaints)
  yield takeEvery(GET_COMPLAINT_DETAILS, fetchComplaintDetails)
  yield takeEvery(CREATE_COMPLAINT, onCreateComplaint)
  yield takeEvery(UPDATE_COMPLAINT, onUpdateComplaint)
  yield takeEvery(DELETE_COMPLAINT, onDeleteComplaint)
}

export default complaintSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
