import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"

// Import Routes all
import { superAdminRoutes, publicRoutes, commonRoute } from "./routes"

import AuthMiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/custom.scss"
import "./assets/scss/Pages.scss"

import { getSettings } from "store/actions"

const token = sessionStorage.getItem("token")
const role = sessionStorage.getItem("role")

const App = props => {
  const dispatch = useDispatch()

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }))

  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    if (token || user?.token) {
      dispatch(getSettings())
    }
  }, [dispatch, token, user])

  function handleRoutesByRole() {
    let Routes = commonRoute
    switch (user?.role ? user?.role : role) {
      case "admin":
        Routes = superAdminRoutes
        break
      default:
        Routes = commonRoute
        break
    }
    return Routes
  }

  useEffect(() => {
    handleRoutesByRole()
  }, [user?.token, user?.role])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {handleRoutesByRole()?.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
              token={user?.token}
            />
          ))}

          <Route render={() => <Redirect to={{ pathname: "/404" }} />} />
          {!token && (
            <Route render={() => <Redirect to={{ pathname: "/login" }} />} />
          )}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
