import {
  GET_PREORDERS,
  GET_PREORDERS_SUCCESS,
  GET_PREORDERS_FAIL,
  GET_PREORDER_DETAILS,
  GET_PREORDER_DETAILS_SUCCESS,
  GET_PREORDER_DETAILS_FAIL,
  CREATE_PREORDER,
  CREATE_PREORDER_SUCCESS,
  CREATE_PREORDER_FAIL,
  UPDATE_PREORDER,
  UPDATE_PREORDER_SUCCESS,
  UPDATE_PREORDER_FAIL,
  DELETE_PREORDER_SUCCESS,
  DELETE_PREORDER_FAIL,
  DELETE_PREORDER,
} from "./actionTypes"

// PreOrder - This line cannot be edited or removed
export const getPreOrders = (page, sort, limit, searchText) => ({
  type: GET_PREORDERS,
  payload: { page, sort, limit, searchText },
})

export const getPreOrdersSuccess = PreOrders => ({
  type: GET_PREORDERS_SUCCESS,
  payload: PreOrders,
})

export const getPreOrdersFail = error => ({
  type: GET_PREORDERS_FAIL,
  payload: error,
})

export const getPreOrderDetails = PreOrderId => ({
  type: GET_PREORDER_DETAILS,
  payload: PreOrderId,
})

export const getPreOrderDetailsSuccess = PreOrderDetails => ({
  type: GET_PREORDER_DETAILS_SUCCESS,
  payload: PreOrderDetails,
})

export const getPreOrderDetailsFail = error => ({
  type: GET_PREORDER_DETAILS_FAIL,
  payload: error,
})

export const createPreOrder = (preOrder, onCloseClick) => ({
  type: CREATE_PREORDER,
  payload: { preOrder, onCloseClick },
})

export const createPreOrderSuccess = preorder => ({
  type: CREATE_PREORDER_SUCCESS,
  payload: preorder,
})

export const createPreOrderFail = error => ({
  type: CREATE_PREORDER_FAIL,
  payload: error,
})

export const getPreOrderDetail = PreOrderId => ({
  type: GET_PREORDER_DETAILS,
  payload: PreOrderId,
})

export const updatePreOrder = (preorder, preOrderId, onCloseClick) => ({
  type: UPDATE_PREORDER,
  payload: { preorder, preOrderId, onCloseClick },
})

export const updatePreOrderSuccess = PreOrder => ({
  type: UPDATE_PREORDER_SUCCESS,
  payload: PreOrder,
})

export const updatePreOrderFail = error => ({
  type: UPDATE_PREORDER_FAIL,
  payload: error,
})

export const deletePreOrder = (preOrderId, history) => ({
  type: DELETE_PREORDER,
  payload: { preOrderId, history },
})

export const deletePreOrderSuccess = PreOrder => ({
  type: DELETE_PREORDER_SUCCESS,
  payload: PreOrder,
})

export const deletePreOrderFail = error => ({
  type: DELETE_PREORDER_FAIL,
  payload: error,
})
