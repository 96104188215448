module.exports = {
  primaryColor: "#22b3be",
  secondaryColor: "ed475f",
  companyName: "Wood log",
  shortName: "Wood log",
  siteMetadata: {
    websiteTitle: "Wood log",
    description: "Wood log",
    keywords: "",
    faviconUrl: "",
    logoUrl: "",
    secondaryLogo: "",
  },
  apiEndpointDev: "http://192.168.1.41:4005",
  apiEndpointProd: "https://api.woodlog.vingb.com/",
  frontEndUrl: "https://woodlog.vingb.com/",
  tokenPrefix: "woodlog_token",
}
