import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  Badge,
  FormGroup,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

import "../../assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { deleteBid, getAllProducts, getBids } from "store/actions"
import PreOrderModal from "./Crud/Modal"
import DeleteModal from "components/Common/DeleteModal"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Select from "react-select"
import AddressModal from "./Crud/AddressModal"

const Biddings = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { Bidding, total, loading, products } = useSelector(state => ({
    Bidding: state?.Bid?.bids?.bids,
    total: state?.Bid?.bids?.total,
    loading: state?.Bid?.loading,
    products: state.Products.products,
  }))

  const [preOrderModal, setPreOrderModal] = useState({
    status: false,
    data: {},
  })
  console.log(Bidding)
  const [sort, setSort] = useState("")

  //pagination
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [page, setPage] = useState(1)
  const [finalDateFormatted, setFinalDateFormatted] = useState({
    from: "",
    to: "",
  })
  const [productSelect, setProductSelect] = useState({})
  const [productSearch, setProductSearch] = useState()
  const [addressModal, setAddressModal] = useState(false)
  const [selectedItemId, setSelectedItemId] = useState(null)
  console.log(selectedItemId)
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getBids(
        page,
        sort,
        totalItemLimit,
        finalDateFormatted,
        productSelect?.id,
        ""
      )
    )
  }, [
    dispatch,
    page,
    sort,
    finalDateFormatted,
    totalItemLimit,
    productSelect?.id,
  ])

  useEffect(() => {
    dispatch(getAllProducts(1, "all", "", productSearch))
  }, [dispatch, productSearch])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteBid(isOpen?.id, ""))
  }

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }
  const tableTitles = [
    {
      title: "All",
      value: "",
    },
    {
      title: "PENDING",
      value: "pending",
    },

    {
      title: "APPROVED",
      value: "approved",
    },
    {
      title: "CANCELLED",
      value: "cancelled",
    },
  ]

  const listChange = sortValue => {
    setSort(sortValue)
    setPage(1)
    history?.push({
      pathname: location.pathname,
      search: `p=${1}&s=${sortValue}`,
      state: {
        page: 1,
        sort: sortValue,
      },
    })
  }
  const handleStatusCheck = status => {
    switch (status) {
      case "approved":
        return true
      default:
        return false
    }
  }
  const columns = [
    {
      dataField: "sl",
      text: "Sl",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "product.primaryLang.name",
      text: "Product",
      sort: true,
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]
  console.log(Bidding)
  const orderData = map(Bidding, (item, index) => ({
    ...item,
    sl: <span>{(page - 1) * 10 + (index + 1)}</span>,
    createdAt: moment(item.createdAt).format("ll"),
    productName: (
      <div className="d-flex align-items-center">
        <img
          // src={item?.product?.images[0]?.url}
          alt="product image"
          className="avatar-sm me-2"
        />
        <p className="p-0 m-0">{item?.product?.primaryLang?.name}</p>
      </div>
    ),
    action: (
      <div>
        <Button id={`view`} type="button" color="white" className="ms-1 btn">
          <Link
            to={`#`}
            onClick={() => setPreOrderModal({ status: true, data: item })}
          >
            <i
              className="bx bx-edit-alt"
              style={{ color: "green", fontSize: "18px" }}
            />
          </Link>
        </Button>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash"
            style={{ color: "red", fontSize: "18px" }}
          />
        </Button>
        {handleStatusCheck(item?.status) ? (
          <Button
            type="button"
            className="p-1 px-2 btn-outline-success"
            color=""
            onClick={() => {
              setAddressModal(true)
              setSelectedItemId(item._id)
            }}
          >
            Convert To Order
          </Button>
        ) : (
          <Button type="button" className="p-1 px-2 " color="">
            &nbsp;{" "}
          </Button>
        )}
      </div>
    ),
  }))

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem("orderLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("orderLimit", e.target.value)
    }
  }
  const debounceProductSearch = debounce(value => setProductSearch(value), 600)

  const handleProductEnters = textEntered => {
    debounceProductSearch(textEntered)
  }

  function handlerProductFinalValue(event) {
    const product = {
      ...productSelect,
      title: event.value !== "loading" && event.label,
      id: event.value,
    }
    setProductSelect(product)
  }

  const productOptions = (products?.length >= 1 ? products : [])?.map(
    (result, index) => ({
      key: index,
      label: result?.primaryLang?.name,
      value: result?._id,
    })
  )

  const modifiedOption = [
    {
      key: 1234,
      label: "All",
      value: "",
    },
    ...productOptions,
  ]
  const headerOptions = (
    <>
      <Row className="mb-3">
        <Col sm="3" className=" d-flex align-items-center">
          <div
            className="btn-group  mt-xl-0"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            {map(tableTitles, (head, key) => (
              <Fragment key={key}>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id={head.value + key}
                  autoComplete="off"
                  checked={sort === head.value}
                  onChange={() => listChange(head?.value || "")}
                />
                <label
                  className="btn btn-light d-flex align-items-center w-100"
                  htmlFor={head.value + key}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {head?.title}

                  <Badge
                    className={`font-size-12 ms-2 ${
                      sort === head.value
                        ? "badge-soft-warning"
                        : "badge-soft-light"
                    }`}
                    pill
                  >
                    {head?.count}
                  </Badge>
                </label>
              </Fragment>
            ))}
          </div>
        </Col>
        <Col sm="7">
          <AvForm className="form-horizontal mx-2 mb-2 d-flex align-items-center justify-content-center">
            <div className="mx-1">
              <span className="">
                Show
                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
              </span>
            </div>
            <div className="" style={{ width: "50px" }}>
              <AvField
                name="pageLimit"
                className="form-control"
                value={totalItemLimit}
                onChange={e => handlePageLimit(e)}
                type="text"
                required
              />
            </div>
          </AvForm>
        </Col>
        <Col sm="2">
          <Button
            type="button"
            color="success"
            className="btn-rounded   me-2"
            onClick={() => setPreOrderModal({ status: true })}
          >
            <i className="mdi mdi-plus me-1 " /> New Bid
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm="5">
          <div className="d-flex sortWrapper mx-2 me-3 align-items-center ">
            <p className="mb-0 pb-0 mx-1" style={{ whiteSpace: "nowrap" }}>
              Sort By Date :
            </p>
            <AvForm>
              <div className="d-flex date_wrapper">
                <div className="d-flex dateRange1 align-items-start position-relative">
                  <AvField
                    name="dateRange"
                    type="date"
                    validate={{
                      dateRange: {
                        start: {
                          value: -5,
                          units: "years",
                        },
                        end: { value: 5, units: "years" },
                      },
                    }}
                    onChange={e =>
                      setFinalDateFormatted({
                        ...finalDateFormatted,
                        from: e.target.value,
                      })
                    }
                    value={finalDateFormatted?.from}
                  />
                  <button
                    onClick={() =>
                      setFinalDateFormatted({
                        ...finalDateFormatted,
                        from: "",
                      })
                    }
                    type="button"
                    className="btn btn-white editable-cancel date_close btn-sm"
                  >
                    <i className="mdi mdi-close text-danger "></i>
                  </button>
                </div>
                <div className="d-flex dateRange1 align-items-start position-relative">
                  <AvField
                    name="dateRange"
                    className="mx-1"
                    type="date"
                    validate={{
                      dateRange: {
                        start: {
                          value: -5,
                          units: "years",
                        },
                        end: { value: 5, units: "years" },
                      },
                    }}
                    onChange={e =>
                      setFinalDateFormatted({
                        ...finalDateFormatted,
                        to: e.target.value,
                      })
                    }
                    value={finalDateFormatted?.to}
                  />
                  <button
                    onClick={() =>
                      setFinalDateFormatted({
                        ...finalDateFormatted,
                        to: "",
                      })
                    }
                    type="button"
                    className="btn btn-white editable-cancel date_close btn-sm"
                  >
                    <i className="mdi mdi-close text-danger "></i>
                  </button>
                </div>
              </div>
            </AvForm>
          </div>
        </Col>
        <Col sm="4">
          <FormGroup className="mb-3">
            <div className="ajax-select mt-lg-0 select2-container">
              <Select
                onInputChange={handleProductEnters}
                value={productSelect?.title}
                placeholder={productSelect?.title}
                onChange={handlerProductFinalValue}
                options={modifiedOption}
                classNamePrefix="select2-selection"
                isLoading={loading}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>

      {/* <div className="search-box ms-2 d-inline-block mb-3">
            <div className="position-relative">
              <AvForm>
                <AvField
                  name="searchText"
                  placeholder="Search"
                  type="text"
                  onChange={e => debounceOrderSearch(e.target.value)}
                />
              </AvForm>
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div> */}
      <Row className="d-flex justify-content-end"></Row>
    </>
  )
  console.log(preOrderModal)
  return (
    <React.Fragment>
      {preOrderModal?.status && (
        <PreOrderModal
          onCloseClick={() => setPreOrderModal({ status: false, data: {} })}
          show={preOrderModal?.status}
          updateData={preOrderModal?.data}
        />
      )}
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <AddressModal
        show={addressModal}
        onCloseClick={() => setAddressModal(false)}
        selectedItemId={selectedItemId}
      />
      <Row>
        <Col className="col-12">
          <Card className="pt-3">
            <CardBody className="pt-0">
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <div className="order-option-table">{headerOptions}</div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {Bidding?.length ? (
                          <Row>
                            <p>Total Biddings :&nbsp;{total ? total : 0} </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  key={"_id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  classes={
                                    "table align-middle table-nowrap custom-padding table-striped"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Biddings!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Biddings
