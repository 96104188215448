import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Spinner,
  CardTitle,
} from "reactstrap"

//actions
import { getDashboardData } from "store/dashboard/actions"
import MetaTag from "components/Common/Meta-tag"
import { map } from "lodash"
import { Link } from "react-router-dom"
import { useSiteVersion } from "hooks/useSiteVersion"
import { orderImg, productImg, users } from "assets/images"

const Dashboard = () => {
  const dispatch = useDispatch()
  const { isPremium, isBasic } = useSiteVersion()
  const { dashboardData, loading, classificationInputs, logistics } =
    useSelector(state => ({
      dashboardData: state.Dashboard.dashboardData,
      loading: state.Dashboard.loading,
      classificationInputs:
        state.Settings.settings?.setup?.classificationInputs,
      logistics: state.Settings.settings.logistics,
    }))

  useEffect(() => {
    dispatch(getDashboardData())
  }, [dispatch])

  const userPercentage = (
    ((dashboardData?.thisMonthUsersCount - dashboardData?.lastMonthUsersCount) /
      dashboardData?.lastMonthUsersCount) *
    100
  ).toFixed(0)
  const isUsersHigher =
    dashboardData?.thisMonthUsersCount > dashboardData?.lastMonthUsersCount
  console.log(dashboardData)
  const TopCards = [
    {
      iconClass: orderImg,
      title: "Orders",
      description: dashboardData?.ordersCount ?? 0,
      title2: "New",
      description2: dashboardData?.newOrdersCount ?? 0,
      title3: "Today",
      description3: dashboardData?.todayOrdersCount ?? 0,

      bgColor: "bg-green",
      subList: true,
      list: [
        {
          sbadges: "Online",
          sbadgesRate: `${dashboardData?.onlineOrdersCount ?? 0} | ${
            dashboardData?.newOnlineOrdersCount ?? 0
          } | ${dashboardData?.todayOnlineOrdersCount ?? 0}`,
          sbadgesClass: "success",
        },
        {
          sbadges: "COD",
          sbadgesRate: `${dashboardData?.codOrdersCount ?? 0} | ${
            dashboardData?.newCodOrdersCount ?? 0
          } | ${dashboardData?.todayCodOrdersCount ?? 0}`,
          sbadgesClass: "success",
        },
      ],
      Link: "/orders",
      col: "col-12 col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-5",
    },

    {
      title: "Products",
      iconClass: productImg,
      description: dashboardData?.productsCount ?? 0,
      bgColor: "bg-yellow",
      subList: true,

      list: [
        {
          sbadges: "Stock Out Products",
          sbadgesRate: dashboardData?.outOfStockProductsCount,
          sbadgesClass: "warning",
        },
        {
          sbadges: "Stock Out Varients",
          sbadgesRate: dashboardData?.outOfStockVarientsCount,
          sbadgesClass: "warning",
        },
      ],
      Link: "/products",
      col: "col-12  col-xs-6 col-sm-6 col-md-12 col-lg-3 col-xl-3",
    },

    {
      iconClass: users,
      title: "Users",
      description: dashboardData?.totalUsersCount ?? 0,
      title2: "Today",
      description2: dashboardData?.todayUsersCount ?? 0,
      title3: "Week",
      description3: dashboardData?.thisWeekUsersCount ?? 0,

      bgColor: "bg-whiteSmook",
      subList: true,
      list: [
        {
          sbadges: "This Month",
          sbadgesRate: `${dashboardData?.thisMonthUsersCount}`,
          // sbadgesPercentage: `${(userPercentage ?? "0") + `%`}`,
          sbadgesPercentage: `${(userPercentage ? userPercentage : "0") + "%"}`,

          sbadgesClass: "success",
          sbadgesPercentageColor: `${isUsersHigher ? "success" : "danger"}`,
        },
        {
          sbadges: "Last Month",
          sbadgesRate: dashboardData?.lastMonthUsersCount,
          sbadgesClass: "success",
        },
      ],
      Link: "/users",
      col: "col-12 col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-4",
    },
    // {
    //   title: "Stores",
    //   iconClass: storeImg,
    //   description: dashboardData?.totalStores ?? 0,
    //   bgColor: "bg-whiteSmook",
    //   subList: false,
    //   list: [],
    //   Link: "/stores",
    //   col: "col-12  col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
    // },
  ]
  console.log(userPercentage)
  const reports = [
    // {
    //   title: "Stores",
    //   iconClass: "bx bx-store-alt",
    //   description: `${dashboardData?.totalStores}`,
    //   wbadges: "",
    //   wbadgesRate: "",
    //   sbadges: "",
    //   sbadgesRate: "",
    //   sbadgesClass: "",
    //   Link: "/stores",
    //   col: { md: 3 },
    // },
    {
      title: "Categories",
      iconClass: "bx bxs-collection",
      description: dashboardData?.categoriesCount ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/categories",
      col: { md: 2 },
    },
    {
      title: "Products",
      iconClass: "bx bxs-extension",
      description: dashboardData?.productsCount ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/products",
      col: { md: 2 },
    },
    {
      title: "Order",
      iconClass: "fas fa-layer-group",
      description: dashboardData?.ordersCount ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/orders",
      col: { md: 2 },
    },
    {
      title: "Banner",
      iconClass: "fas fa-ticket-alt",
      description: dashboardData?.totalCoupons ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/banners",
      col: { md: 2 },
    },
    {
      title: "Bidding",
      iconClass: "fas fa-gavel",
      description: dashboardData?.totalGroups ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/bidding",
      col: { md: 2 },
    },
    {
      title: "Transaction",
      iconClass: "far fa-credit-card",
      description: dashboardData?.totalCoupons ?? 0,
      wbadges: "",
      wbadgesRate: "",
      Link: "/transaction-list",
      col: { md: 2 },
    },
  ]

  const logisticsData = [
    {
      title: "Shiprocket Wallet",
      iconClass: "fas fa-ticket-alt",
      description: `₹${dashboardData?.shiprocketWalletBalance ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "New Orders",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketNewOrders ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "PickUps",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "In Transit",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketInTransit ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "Delivered",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
    {
      title: "NDR",
      iconClass: "fas fa-ticket-alt",
      description: `${dashboardData?.shiprocketPickUps ?? 0}`,
      wbadges: "",
      wbadgesRate: "",
      Link: "!#",
      col: { md: 2 },
    },
  ]
  const classification = []

  const ids = classificationInputs?.map(i => i.name)

  const handleReport = () => {
    if (isPremium) {
      return reports
    } else if (isBasic)
      return reports?.filter(
        i => i.title !== "Coupons" && i.title !== "Collections"
      )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTag title="Dashboard" />
        <Container fluid>
          {/* <CardTitle>Dashboard</CardTitle> */}
          {loading ? (
            <Spinner color="secondary" className="d-block m-auto" />
          ) : (
            <>
              <p>Summary</p>
              <hr />
              <Row>
                {map(TopCards, (item, key) => (
                  <Col className={item.col} key={key}>
                    <Card className={"mega-stats-wid " + item.bgColor}>
                      <Link to={item.Link}>
                        <CardBody
                          style={{ height: "200px" }}
                          className={
                            "d-flex flex-column justify-content-between " +
                            item.subList
                              ? "align-items-start"
                              : "align-items-center"
                          }
                        >
                          <div
                            className={`d-flex flex-wrap align-items-center mb-3 ${
                              item?.subList === false && "flex-column"
                            }`}
                          >
                            {/* <div className="avatar-title revenue-avatar bg-light rounded-circle text-primary font-size-20">
                              <img
                                src={item.iconClass}
                                alt=""
                                className="w-100 h-100"
                              />
                            </div> */}
                            <div className="d-flex">
                              <Row className="d-flex justify-content-center">
                                <Col
                                  className={`mx-3 ${
                                    item?.subList === false &&
                                    "mt-2 text-center"
                                  }`}
                                >
                                  <h5 className="mb-2">
                                    <span className="mb-2 text_md_black">
                                      {item?.title}
                                    </span>
                                  </h5>
                                  <h3 className="text_deals_green text-center">
                                    {item?.description ?? 0}
                                  </h3>
                                </Col>
                                {item?.title2 && (
                                  <Col
                                    className={`mx-3 ${
                                      item?.subList === false &&
                                      "mt-2 text-center"
                                    }`}
                                  >
                                    <h5 className="mb-2">
                                      <span className="mb-2 text_md_black">
                                        {item?.title2}
                                      </span>
                                    </h5>
                                    <h3 className="text_deals_green text-center">
                                      {item?.description2 ?? 0}
                                    </h3>
                                  </Col>
                                )}
                                {item?.title3 && (
                                  <Col
                                    className={`mx-3 ${
                                      item?.subList === false &&
                                      "mt-2 text-center"
                                    }`}
                                  >
                                    <h5 className="mb-2">
                                      <span className="mb-2 text_md_black">
                                        {item?.title3}
                                      </span>
                                    </h5>
                                    <h3 className="text_deals_green text-center">
                                      {item?.description3 ?? 0}
                                    </h3>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>

                          {map(item?.list, (subItem, subKey) => (
                            <span
                              className="d-flex align-items-center mb-2 text_md_black"
                              key={subKey}
                            >
                              <i className="bx bx-right-arrow-alt font-size-16 text_deals_yellow mx-2"></i>{" "}
                              {subItem.sbadges}{" "}
                              <p
                                className={
                                  "mb-0 mx-2 text-white fw-medium rounded-pill badge bg-" +
                                  subItem?.sbadgesClass
                                }
                                style={{ fontSize: "14px" }}
                              >
                                {subItem?.sbadgesRate}
                              </p>
                              <p
                                className={
                                  "mb-0 mx-2 fw-medium rounded-pill badge " +
                                  `text-${subItem?.sbadgesPercentageColor}`
                                }
                                style={{ fontSize: "14px" }}
                              >
                                {subItem?.sbadgesPercentage}{" "}
                                {subItem?.sbadgesPercentageColor
                                  ? subItem?.sbadgesPercentageColor ===
                                    "success"
                                    ? "👍"
                                    : "👎"
                                  : ""}
                              </p>
                            </span>
                          ))}
                        </CardBody>
                      </Link>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                {handleReport()?.map((report, key) => (
                  <Col md={report.col.md} key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Link to={report.Link}>
                          <Media>
                            <Media body>
                              <p
                                className="text-muted fw-medium d-flex align-items-center"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                <i
                                  className={`font-size-16 text-success ${report.iconClass}`}
                                ></i>
                                <span className="mx-2">{report.title}</span>
                              </p>
                              <h4 className="mb-0  ">{report.description}</h4>
                            </Media>

                            {/* <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    "text-white fw-medium rounded-pill badge bg-" +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report?.sbadges}
                                </p>
                                <h4 className="mb-0">{report?.sbadgesRate}</h4>
                              </div>
                            </Media> */}

                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p
                                  className={
                                    "text-white fw-medium rounded-pill badge bg-" +
                                    report?.sbadgesClass
                                  }
                                >
                                  {report.wbadges}
                                </p>
                                <h4 className="mb-0">{report.wbadgesRate}</h4>
                              </div>
                            </Media>
                          </Media>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {classification?.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    {ids?.includes(report?.value) ? (
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </Media>

                            {/* badges with success */}
                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p className="text-white fw-medium rounded-pill bg-success badge">
                                  {report.sbadges}
                                </p>
                                <h4 className="mb-0">{report.sbadgesRate}</h4>
                              </div>
                            </Media>

                            {/* badges with warning */}
                            <Media body className="text-center">
                              <div className="flex-grow-1">
                                <p className="text-white fw-medium rounded-pill bg-warning badge">
                                  {report.wbadges}
                                </p>
                                <h4 className="mb-0">{report.wbadgesRate}</h4>
                              </div>
                            </Media>
                          </Media>
                        </CardBody>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Col>
                ))}
              </Row>
              {logistics?.isShiprocketConnected && (
                <>
                  <p>Logistics</p>
                  <hr />
                  <Row>
                    {logisticsData?.map((report, key) => (
                      <Col md={report.col.md} key={"_col_" + key}>
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <Link to={report.Link}>
                              <Media>
                                <Media body>
                                  <p
                                    className="text-muted fw-medium d-flex align-items-center"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <i
                                      className={`font-size-16 text-success ${report.iconClass}`}
                                    ></i>
                                    <span className="mx-2">{report.title}</span>
                                  </p>
                                  <h4 className="mb-0  ">
                                    {report.description}
                                  </h4>
                                </Media>

                                <Media body className="text-center">
                                  <div className="flex-grow-1">
                                    <p
                                      className={
                                        "text-white fw-medium rounded-pill badge bg-" +
                                        report?.sbadgesClass
                                      }
                                    >
                                      {report.wbadges}
                                    </p>
                                    <h4 className="mb-0">
                                      {report.wbadgesRate}
                                    </h4>
                                  </div>
                                </Media>
                              </Media>
                            </Link>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </>
              )}

              {/* <Col className="row">
                <CardTitle>Analytics</CardTitle>
                <MonthlyChart />
              </Col> */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
