import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
} from "./actionTypes"

// Transaction - This line cannot be edited or removed
export const getTransactions = (page, sort, limit, searchText, sortByDate) => ({
  type: GET_TRANSACTIONS,
  payload: { page, sort, limit, searchText, sortByDate },
})

export const getTransactionsSuccess = transactions => ({
  type: GET_TRANSACTIONS_SUCCESS,
  payload: transactions,
})

export const getTransactionsFail = error => ({
  type: GET_TRANSACTIONS_FAIL,
  payload: error,
})
