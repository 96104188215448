import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//Notifications
import Notifications from "pages/Notification/index"

//Product
import Product from "pages/Product/index"
import DeletedProduct from "pages/Product/Deleted/index"
import ProductDetails from "pages/Product/SingleView/index"
import UpdateProduct from "pages/Product/Crud/Update/Update"
import CreateProduct from "pages/Product/Crud/Create/Create"

//Banner
import Banners from "pages/Banner/index"
import UpdateBanner from "pages/Banner/Crud/Update"
import CreateBanner from "pages/Banner/Crud/Create"

//User
import User from "pages/User/index"
import UserDetails from "pages/User/SingleView/index"
import UpdateUser from "pages/User/Crud/Update/Update"

//Order
import Orders from "pages/Order/index"
import OrderDetails from "pages/Order/SingleView/index"

// Settings
import Banner from "pages/Settings/index"
import UpdateSettings from "pages/Settings/Crud/Update/Update"

//Revenue
import Revenues from "pages/Revenue/index"
import RevenueDetail from "pages/Revenue/shopDetail/index"

//Errors
//404
import Pages404 from "pages/error/pages-404"
import Categories from "pages/Categories/index"

import CreateCategory from "pages/Categories/Create/Create"
import UpdateCategory from "pages/Categories/Update/Update"
import CreateAdminOrder from "pages/Order/Crud/Create"
import Reviews from "pages/Reviews"
import PreOrder from "pages/PreOrder"
import Complaints from "pages/Complaint"
import Biddings from "pages/Bidding"
import BidProductDetails from "pages/Bidding/SingleView"
import BidProductDetailss from "pages/Bidding/SingleView"
import TransactionTable from "pages/TransactionsList"

// import UpdateProductStock from "pages/Product/stockUpdate/Update"
// import ProductPages from "pages/product-pages"
// import CratePages from "pages/product-pages/Crud/Create"
// import UpdatePages from "pages/product-pages/Crud/Update"
// import ProductPerformance from "pages/Performance"
// import Blogs from "pages/Blogs"
// import Transactions from "pages/Transactions"
// import Collections from "pages/collections"
// import CrateCollections from "pages/collections/Crud/Create"
// import UpdateCollections from "pages/collections/Crud/Update"
// import Groups from "pages/groups-home"
// import CrateGroups from "pages/groups-home/Crud/Create"
// import UpdateGroups from "pages/groups-home/Crud/Update"
// import Coupons from "pages/coupon"
// import CreateCoupon from "pages/coupon/Crud/Create"
// import UpdateCoupons from "pages/coupon/Crud/Update"

const commonRoute = [
  { path: "/dashboard", component: Dashboard },

  { path: "/logout", component: Logout },

  {
    path: "/",
    exact: true,
    component: function dashboard() {
      return <Redirect to="/dashboard" />
    },
  },
]

const superAdminRoutes = [
  // products
  { path: "/products", component: Product },
  { path: "/products/deleted", component: DeletedProduct },
  { path: "/product/:id", component: ProductDetails },
  { path: "/product/create/new", component: CreateProduct },
  { path: "/product/update/:id", component: UpdateProduct },

  //Classifications
  { path: "/categories", component: Categories },
  { path: "/category/create/new", component: CreateCategory },
  { path: "/category/update/:id", component: UpdateCategory },

  //bidding
  { path: "/bidding", component: Biddings },
  { path: "/bid-product/:id", component: BidProductDetailss },

  // { path: "/stockUpdate", component: UpdateProductStock },
  // transactions
  { path: "/transaction-list", component: TransactionTable },

  //collections
  // { path: "/collections", component: Collections },
  // { path: "/collections/create/new", component: CrateCollections },
  // { path: "/collections/update/:id", component: UpdateCollections },

  //groups
  // { path: "/groups", component: Groups },
  // { path: "/groups/create/new", component: CrateGroups },
  // { path: "/groups/update/:id", component: UpdateGroups },

  //pages
  // { path: "/pages/all", component: ProductPages },
  // { path: "/pages/product/create", component: CratePages },
  // { path: "/pages/product/:id", component: UpdatePages },

  //coupons
  // { path: "/coupons", component: Coupons },
  // { path: "/coupons/create/new", component: CreateCoupon },
  // { path: "/coupons/update/:id", component: UpdateCoupons },

  // transactions
  // { path: "/transactions", component: Transactions },

  //Performance
  // { path: "/performance/product", component: ProductPerformance },

  //area
  // { path: "/area", component: AreaList },

  // Users
  { path: "/users", component: User },
  { path: "/users/:id", component: UserDetails },
  { path: "/user/update/:id", component: UpdateUser },

  // Orders
  { path: "/orders", component: Orders },
  { path: "/orders/new", component: CreateAdminOrder },
  { path: "/order/:id", component: OrderDetails },

  // Complaint
  { path: "/complaints", component: Complaints },

  // Pre Orders
  { path: "/pre-orders", component: PreOrder },

  //Dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //Notifications
  { path: "/notifications", component: Notifications },

  //Banner
  { path: "/banners", component: Banners },
  { path: "/banner/create/new", component: CreateBanner },
  { path: "/banner/update/:id", component: UpdateBanner },

  //review
  { path: "/reviews", component: Reviews },

  //biddings
  // { path: "/blogs", component: Blogs },

  //Settings
  { path: "/superadmin/settings", component: Banner },
  { path: "/superadmin/settings/:id", component: UpdateSettings },

  //Revenues
  { path: "/revenues", component: Revenues },
  { path: "/revenue/:id", component: RevenueDetail },

  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/404", component: Pages404 },
]

export { publicRoutes, superAdminRoutes, commonRoute }
