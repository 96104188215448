import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_PREORDERS,
  GET_PREORDER_DETAILS,
  CREATE_PREORDER,
  UPDATE_PREORDER,
  DELETE_PREORDER,
} from "./actionTypes"
import {
  getPreOrdersFail,
  getPreOrdersSuccess,
  getPreOrderDetailsSuccess,
  getPreOrderDetailsFail,
  createPreOrderFail,
  createPreOrderSuccess,
  updatePreOrderSuccess,
  updatePreOrderFail,
  deletePreOrderSuccess,
  deletePreOrderFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// PreOrder - This line cannot be edited or removed
function getPreOrdersAPi({ page, sort, limit, searchText }) {
  return get(
    `/preorder/admin/all?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getPreOrderDetailsAPi = PreOrderId => {
  return get(`/preorder/admin/${PreOrderId}`)
}

const createPreOrderApi = ({ preOrder }) => {
  return post("/preorder/admin/new", preOrder)
}

const updatePreOrderApi = ({ preorder, preOrderId }) => {
  return ApiPut(`/preorder/admin/${preOrderId}`, preorder)
}

const deletePreOrderApi = PreOrderId => {
  return del(`/preorder/admin/${PreOrderId}`)
}

function* fetchPreOrders({ payload }) {
  try {
    const response = yield call(getPreOrdersAPi, payload)
    yield put(getPreOrdersSuccess(response))
  } catch (error) {
    yield put(getPreOrdersFail(error))
  }
}

function* fetchPreOrderDetails({ payload: PreOrderId }) {
  try {
    const response = yield call(getPreOrderDetailsAPi, PreOrderId)
    yield put(getPreOrderDetailsSuccess(response))
  } catch (error) {
    yield put(getPreOrderDetailsFail(error))
  }
}

function* onCreatePreOrder({ payload }) {
  try {
    const response = yield call(createPreOrderApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createPreOrderSuccess(response))
      payload?.onCloseClick()
      doneNotification("PreOrder Created Successfully!")
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createPreOrderFail(error))
  }
}

function* onUpdatePreOrder({ payload }) {
  try {
    const response = yield call(updatePreOrderApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "PreOrder Updated Successfully!",
        title: "",
      })
      yield put(updatePreOrderSuccess(response))
      if (payload.onCloseClick) {
        payload.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updatePreOrderFail(error.response.data))
  }
}

function* onDeletePreOrder({ payload }) {
  try {
    const response = yield call(deletePreOrderApi, payload.preOrderId)
    yield put(deletePreOrderSuccess(response))
    Notification({
      type: "success",
      message: "PreOrder Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deletePreOrderFail(error?.response?.data))
  }
}

function* preorderSaga() {
  yield takeEvery(GET_PREORDERS, fetchPreOrders)
  yield takeEvery(GET_PREORDER_DETAILS, fetchPreOrderDetails)
  yield takeEvery(CREATE_PREORDER, onCreatePreOrder)
  yield takeEvery(UPDATE_PREORDER, onUpdatePreOrder)
  yield takeEvery(DELETE_PREORDER, onDeletePreOrder)
}

export default preorderSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
