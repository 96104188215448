export const sidebarData = [
  {
    routeLink: "/#",
    icon: "bx bx-home-circle",
    title: "Dashboard",
  },
  {
    routeLink: "/orders",
    icon: "bx bxs-shopping-bags",
    title: "Orders",
  },
  {
    routeLink: "/pre-orders",
    icon: "bx bx-store-alt",
    title: "Preorders",
  },
  {
    routeLink: "/products",
    icon: "bx bxs-package",
    title: "Products",
  },
  {
    routeLink: "/reviews",
    icon: "bx bxs-award",
    title: "Reviews",
  },
  {
    routeLink: "/complaints",
    icon: "bx bx-file",
    title: "Complaints",
  },
  {
    routeLink: "/bidding",
    icon: "bx bx-pyramid",
    title: "Bidding",
  },
  {
    routeLink: "/transaction-list",
    icon: "bx bx-wallet-alt",
    title: "Transaction",
  },
  // {
  //   routeLink: "/blogs",
  //   icon: "bx bx-file",
  //   title: "Blogs",
  // },
  // {
  //   routeLink: "/stockUpdate",
  //   icon: "bx bx-store-alt",
  //   title: "Stock & Offer",
  // },
  {
    routeLink: "/#",
    icon: "",
    title: "Site settings",
    isSeparator: true,
  },
  {
    routeLink: "/categories",
    icon: "bx bxs-collection",
    title: "Categories",
  },
  // {
  //   routeLink: "/collections",
  //   icon: "bx bxs-extension",
  //   title: "Collections",
  // },
  // {
  //   routeLink: "/groups",
  //   icon: "fas fa-layer-group",
  //   title: "Groups",
  // },
  // {
  //   routeLink: "/pages/all",
  //   icon: "bx bx-food-menu",
  //   title: "Pages",
  // },

  {
    routeLink: "/banners",
    icon: "bx bxs-image",
    title: "Banners",
  },

  // {
  //   routeLink: "/coupons",
  //   icon: "bx bxs-coupon",
  //   title: "Coupon",
  // },
  {
    routeLink: "/#",
    icon: "",
    title: "Extras",
    isSeparator: true,
  },
  // {
  //   routeLink: "/biddings",
  //   icon: "bx bxs-extension",
  //   title: "Biddings",
  // },
  {
    routeLink: "/users",
    icon: "bx bxs-user",
    title: "Users",
  },
  {
    routeLink: "/revenues",
    icon: "bx bxs-landmark",
    title: "Revenue",
  },
  // {
  //   routeLink: "/transactions",
  //   icon: "bx bx-transfer",
  //   title: "Transactions",
  // },
  // {
  //   routeLink: "/performance/product",
  //   icon: "bx bxs-rocket",
  //   title: "Performance",
  // },
  {
    routeLink: "/superadmin/settings",
    icon: "bx bxs-check-shield",
    title: "Admin Settings",
  },
]

export const promoterDashboard = [
  { title: "Dashboard", routeLink: "/dashboard", icon: "bx bxs-home" },
  {
    title: " Page Views",
    routeLink: "/promoter-page-view",
    icon: "bx bx-show-alt",
  },
  {
    title: " Earnings",
    routeLink: "/promoter-earnigs",
    icon: "bx bx-dollar",
  },
  {
    title: "Products",
    routeLink: "/promoter/products",
    icon: "bx bxs-package",
  },
]
