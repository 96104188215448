import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

import "../../assets/scss/datatables.scss"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"

import { deleteComplaint, getComplaints } from "store/actions"
import PreOrderModal from "./Crud/Modal"
import DeleteModal from "components/Common/DeleteModal"

const DataTable = () => {
  const dispatch = useDispatch()

  const { complaints, total, loading } = useSelector(state => ({
    complaints: state?.Complaint?.complaints?.complaints,
    total: state?.Complaint?.complaints?.total,
    loading: state?.Complaint?.loading,
  }))

  const [orderSearchText, setOrderSearchText] = useState("")
  const [preOrderModal, setPreOrderModal] = useState({
    status: false,
    data: {},
  })

  //pagination
  const [totalItemLimit, setTotalItemLimit] = useState(30)
  const [page, setPage] = useState(1)
  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getComplaints(page, "", totalItemLimit, orderSearchText))
  }, [dispatch, page, orderSearchText, totalItemLimit])

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })

  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteComplaint(isOpen?.id, ""))
  }

  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  const columns = [
    {
      dataField: "sl",
      text: "Sl",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone No.",
      sort: true,
    },

    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    // {
    //   dataField: "productName",
    //   text: "Product",
    //   sort: true,
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  console.log(complaints)

  const orderData = map(complaints, (item, index) => ({
    ...item,
    sl: <span>{(page - 1) * 10 + (index + 1)}</span>,
    createdAt: moment(item.createdAt).format("ll"),
    productName: (
      <div className="d-flex align-items-center">
        <img
          // src={item?.product?.images[0]?.url}
          alt="product image"
          className="avatar-sm me-2"
        />
        <p className="p-0 m-0">{item?.product?.primaryLang?.name}</p>
      </div>
    ),
    action: (
      <div>
        <Button id={`view`} type="button" color="white" className="ms-1 btn">
          <Link
            to={`#`}
            onClick={() => setPreOrderModal({ status: true, data: item })}
          >
            <i
              className="bx bx-edit-alt"
              style={{ color: "green", fontSize: "18px" }}
            />
          </Link>
        </Button>
        <Button
          id={`Delete${index}`}
          type="button"
          color="white"
          className="btn "
          onClick={() => {
            handleDelete(item._id)
          }}
        >
          <i
            className="bx bx-trash"
            style={{ color: "red", fontSize: "18px" }}
          />
        </Button>
      </div>
    ),
  }))

  const debounceOrderSearch = debounce(value => setOrderSearchText(value), 600)

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  useEffect(() => {
    const currentLimit = localStorage.getItem("orderLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("orderLimit", e.target.value)
    }
  }

  const headerOptions = (
    <div
      className={`headerOptionWrap d-flex flex-wrap align-items-center justify-content-between`}
    >
      <div className="headerOptionSubWrap d-flex align-items-center flex-wrap justify-content-between w-100">
        <div className="d-flex align-items-center ">
          <div className="search-box ms-2 d-inline-block mb-3">
            <div className="position-relative">
              <AvForm>
                <AvField
                  name="searchText"
                  placeholder="Search"
                  type="text"
                  onChange={e => debounceOrderSearch(e.target.value)}
                />
              </AvForm>
              <i className="bx bx-search-alt search-icon" />
            </div>
          </div>

          <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
            <div className="mx-1">
              <span className="">
                Show
                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
              </span>
            </div>
            <div className="" style={{ width: "50px" }}>
              <AvField
                name="pageLimit"
                className="form-control"
                value={totalItemLimit}
                onChange={e => handlePageLimit(e)}
                type="text"
                required
              />
            </div>
          </AvForm>
        </div>

        <Button
          type="button"
          color="success"
          className="btn-rounded  mb-2 me-2"
          onClick={() => setPreOrderModal({ status: true })}
        >
          <i className="mdi mdi-plus me-1 " /> New Complaint
        </Button>
      </div>
    </div>
  )

  return (
    <React.Fragment>
      {preOrderModal?.status && (
        <PreOrderModal
          onCloseClick={() => setPreOrderModal({ status: false, data: {} })}
          show={preOrderModal?.status}
          updateData={preOrderModal?.data}
        />
      )}
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Row>
        <Col className="col-12">
          <Card className="pt-3">
            <CardBody>
              <ToolkitProvider
                keyField="_id"
                columns={columns}
                data={orderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <div className="order-option-table">{headerOptions}</div>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        {complaints?.length ? (
                          <Row>
                            <p>Total Complaints :&nbsp;{total ? total : 0} </p>

                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  // keyField={"id"}
                                  key={"_id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  classes={
                                    "table align-middle table-nowrap custom-padding table-striped"
                                  }
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  onTableChange={handleTableChange}
                                  // rowClasses={"table-info"}
                                />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <p className="my-2 mx-3 ">No Complaints!</p>
                        )}
                        <MyPagination
                          totalPages={pages}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default DataTable
