import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import { arrayMoveImmutable } from "array-move"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import { SortableContainer, SortableElement } from "react-sortable-hoc"
import { useEffect, useRef, useState } from "react"

import "react-circular-progressbar/dist/styles.css"

import { API_BASE_URL, post } from "helpers/api_helper"
import { Notification } from "components/Common/Notification"

const UseVideoUploader = ({
  setVideosPreview, //function
  videosPreview, //array
  deleteUrl, //string
  videoSize, //number
  isMultiple, //bool
  onChangeUpdate, //function update when the video is changes
}) => {
  const deleteDefaultUrl = deleteUrl ? deleteUrl : "/media/admin/delete"
  const uploadBaseUrl = `/media/video/admin/upload`

  const [selectedVideoDetails, setSelectedVideoDetails] = useState()
  const [videoUploading, setVideoUploading] = useState({ num: 0 })
  const [videoLoading, setVideoLoading] = useState({
    status: false,
    isDeleteLoad: false,
    id: "",
  })
  const [fileSize, setFileSize] = useState("")
  const load = useRef(0)

  useEffect(() => {
    if (videoUploading.num >= 10 && videoLoading.status === true) {
      if (load.current < 90 && videoUploading.num < 90) {
        load.current += 1
        const timer = setTimeout(
          () => setVideoUploading({ num: videoUploading.num + 6 }),
          1000
        )
        return () => clearTimeout(timer)
      }
    }
    if (videoUploading.num === 100) {
      setTimeout(() => {
        setVideoUploading({ num: 0 })
      }, 500)
    }
  }, [videoUploading])

  const onChangeVideoHandler = async e => {
    setVideoLoading({ ...videoLoading, status: true })
    setVideoUploading({ ...videoUploading, num: 10 })
    const files = e

    function bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"]
      if (bytes == 0) return "0 Byte"
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return {
        size: Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i],
        sizeInKb: Math.round(bytes / 1024),
      }
    }

    const { size, sizeInKb } = bytesToSize(files[0]?.size)

    setSelectedVideoDetails(size)

    if (sizeInKb >= videoSize * 1024) {
      setFileSize(
        size + " file size is too large - Max Limit " + videoSize + "MB"
      )
      setVideoLoading({ ...videoLoading, status: false })
      setVideoUploading({ ...videoUploading, num: 0 })
    } else {
      setFileSize("")
      const formData = new FormData()
      files.forEach((file, key) => {
        formData.append("video", file) // Append the video file to FormData
      })

      const token = sessionStorage.getItem("token")

      // Make the API call with FormData
      try {
        const res = await fetch(`${API_BASE_URL}${uploadBaseUrl}`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        if (res.ok) {
          const responseJson = await res.json()
          setVideosPreview([...videosPreview, responseJson])
          setVideoLoading({ ...videoLoading, status: false })
          setVideoUploading({ ...videoUploading, num: 100 })
          Notification({
            type: "success",
            message: "Video uploaded",
            title: "",
          })
          if (onChangeUpdate) {
            onChangeUpdate(responseJson?.public_id, "add", responseJson)
          }
        } else {
          throw new Error("Video upload failed")
        }
      } catch (error) {
        console.log(error)
        Notification({
          type: "error",
          message: "Video upload failed, try again",
          title: "",
        })
        setVideoLoading({ ...videoLoading, status: false })
        setVideoUploading({ ...videoUploading, num: 0 })
      }
    }
  }

  //delete handler
  const handleRemoveVideo = async (id, hideNtf) => {
    setVideoLoading({ ...videoLoading, isDeleteLoad: true, id: id })
    try {
      const res = await post(deleteDefaultUrl, {
        public_id: id,
      })
      if (res?.result === "ok" || res?.result === "not found") {
        if (!hideNtf) {
          setVideosPreview(videosPreview?.filter(img => img.public_id !== id))
          Notification({
            type: "success",
            message: "Video removed",
            title: "",
          })
        }
        setVideoLoading({ ...videoLoading, isDeleteLoad: false })
        if (onChangeUpdate) {
          onChangeUpdate(id, "remove")
        }
      } else {
        Notification({
          type: "error",
          message: "Video delete faild, try again",
          title: "",
        })
        setVideoLoading({ ...videoLoading, isDeleteLoad: false })
      }
    } catch (error) {
      Notification({
        type: "error",
        message: "Video delete faild, try again",
        title: "",
      })
      setVideoLoading({ ...videoLoading, isDeleteLoad: false })
    }
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setVideosPreview(arrayMoveImmutable(videosPreview, oldIndex, newIndex))
  }

  const SortablePhoto = SortableElement(item => {
    const { value } = item
    return (
      <div style={videoGalleryStyles}>
        <div className="position-relative h-100 w-100 p-1">
          <iframe
            src={value?.url}
            width="200"
            height="150"
            style={{
              height: "150px",
              width: "200px",
              aspectRatio: "16/9",
              borderRadius: "14px",
            }}
            allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
            frameBorder="0"
          ></iframe>
          <>
            <div style={{ ...cropButtonContainer, flexDirection: "row" }}>
              <span style={CropButtonStyle}>
                <div
                  className="mt-1"
                  onMouseDown={() => handleRemoveVideo(value?.public_id)}
                >
                  {videoLoading.isDeleteLoad &&
                  videoLoading.id === value?.public_id ? (
                    <i className="bx bx-loader bx-spin mx-1 font-size-16 align-center"></i>
                  ) : (
                    <i
                      className="bx bx-trash-alt mx-1 font-size-16 bx-tada-hover"
                      style={{ color: "#ff7a7a" }}
                      title={"remove Video"}
                    ></i>
                  )}
                </div>
              </span>
            </div>
          </>
        </div>
      </div>
    )
  })

  const SortableGallery = SortableContainer(({ items }) => (
    <div className="sortable-queue d-flex flex-wrap mx-1">
      {items?.map((item, idx) => (
        <div className="position-relative mb-2" key={idx}>
          <SortablePhoto key={idx} index={idx} value={item} />
        </div>
      ))}

      {videoLoading?.status ? (
        <div style={videoLoaderStyles} className="col-4 mx-1">
          <CircularProgressbarWithChildren
            value={videoUploading.num}
            strokeWidth={3}
          >
            <div style={progressbarStyles}>
              <span>Uploading...</span>
              <span>{videoUploading.num}%</span>
              <span>
                {selectedVideoDetails !== NaN && selectedVideoDetails}
              </span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      ) : (
        ""
      )}
    </div>
  ))

  return (
    <div style={mainContainerStyles} className="video-wrapper">
      <span className="">Video{isMultiple && "s"}</span>
      <div className="form-group mb-0 d-flex rounded-md mt-1">
        <SortableGallery
          items={videosPreview}
          onSortEnd={onSortEnd}
          axis={"xy"}
          hideSortableGhost={true}
          distance={1}
        />
        {isMultiple ? (
          <div style={{ marginTop: 5 }} className="mx-1 custom-file">
            <Dropzone
              onDrop={acceptedFiles => {
                onChangeVideoHandler(acceptedFiles)
              }}
              multiple={false}
              disabled={videoLoading?.status}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" style={dropzoneStyles}>
                  <div
                    className="dz-message needsclick mt-4 h-100 p-0"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="">
                      <i
                        className={
                          "display-4 bx bxs-image-add " +
                          `${videoLoading?.status === true ? "text-muted" : ""}`
                        }
                        style={addIconStyles}
                      />
                    </div>
                    <p className="font-size-13">
                      Click to upload or drag and drop
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        ) : (
          videosPreview?.length == 0 && (
            <div className="mt-2 mx-1 custom-file">
              <Dropzone
                onDrop={acceptedFiles => {
                  onChangeVideoHandler(acceptedFiles)
                }}
                multiple={false}
                disabled={videoLoading?.status}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone" style={dropzoneStyles}>
                    <div
                      className="dz-message needsclick mt-4 h-100 p-0"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="">
                        <i
                          className={
                            "display-4 bx bxs-image-add " +
                            `${
                              videoLoading?.status === true ? "text-muted" : ""
                            }`
                          }
                          style={addIconStyles}
                        />
                      </div>
                      <p className="font-size-13">
                        Click to upload or drag and drop
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          )
        )}
        {fileSize && <p className="text-danger mx-2">{fileSize}</p>}
      </div>
      {videosPreview?.length >= 2 && (
        <span className="mx-2">Drag photo to rearrange</span>
      )}
    </div>
  )
}

//styles

const cropButtonContainer = {
  position: "absolute",
  top: "0",
  left: "0",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  flexDirection: "column",
  cursor: "grab",
}
const CropButtonStyle = {
  background: "#f6f6f6",
  color: "#000",
  borderRadius: "15px",
  padding: "2px 10px 2px 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  marginTop: "10px",
  marginRight: "10px",
  boxShadow: "rgb(0 0 0 / 10%) 0px 10px 50px",
}
const addIconStyles = {
  fontSize: "30px",
  color: "#2e9d5e",
}
const dropzoneStyles = {
  padding: "10px",
}
const mainContainerStyles = {
  border: "1px solid #f8f8fb",
  borderRadius: "10px",
  padding: "15px",
  background: "#f9f9fa38",
}
const imgWithClick = {
  cursor: "pointer",
  // maxWidth: "200px",
  maxHeight: "150px",
  height: "150px",
  borderRadius: "10px",
  minHeight: "50px",
  background: "#f2f2f2",
}
const videoLoaderStyles = {
  height: "150px",
  width: "150px",
  background: "#f8f8fb",
  padding: "1rem",
  marginTop: "8px",
  borderRadius: "6px",
}
const progressbarStyles = {
  fontSize: 11,
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  marginTop: "10px",
}
const videoGalleryStyles = {
  maxHeight: "150px",
}

export default UseVideoUploader

//props
UseVideoUploader.propTypes = {
  setVideosPreview: PropTypes.func,
  videosPreview: PropTypes.any,
  videoSize: PropTypes.number,
  deleteUrl: PropTypes.string,
  isMultiple: PropTypes.bool,
  onChangeUpdate: PropTypes.func,
}
