/* Bids - This line cannot be edited or removed */
export const GET_BIDS = "GET_BIDS"
export const GET_BIDS_SUCCESS = "GET_BIDS_SUCCESS"
export const GET_BIDS_FAIL = "GET_BIDS_FAIL"

/* CREATE Bid */
export const CREATE_BID = "CREATE_BID"
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS"
export const CREATE_BID_FAIL = "CREATE_BID_FAIL"

/* Edit Bid */
export const UPDATE_BID = "UPDATE_BID"
export const UPDATE_BID_SUCCESS = "UPDATE_BID_SUCCESS"
export const UPDATE_BID_FAIL = "UPDATE_BID_FAIL"

/* Delete Bid */
export const DELETE_BID = "DELETE_BID"
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS"
export const DELETE_BID_FAIL = "DELETE_BID_FAIL"

// bid product
export const GET_BIDPRODUCTS = "GET_BIDPRODUCTS"
export const GET_BIDPRODUCTS_SUCCESS = "GET_BIDPRODUCTS_SUCCESS"
export const GET_BIDPRODUCTS_FAIL = "GET_BIDPRODUCTS_FAIL"

/* Bid_DETAILS */
export const GET_BID_DETAILS = "GET_BID_DETAILS"
export const GET_BID_DETAILS_SUCCESS = "GET_BID_DETAILS_SUCCESS"
export const GET_BID_DETAILS_FAIL = "GET_BID_DETAILS_FAIL"
// convert  to order
export const CREATE_ORDER_FROM_BID = "CREATE_ORDER_FROM_BID"
export const CREATE_ORDER_FROM_BID_SUCCESS = "CREATE_ORDER_FROM_BID_SUCCESS"
export const CREATE_ORDER_FROM_BID_FAIL = "CREATE_ORDER_FROM_BID_FAIL"
