import { AvField, AvForm } from "availity-reactstrap-validation"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CardTitle, Col, FormGroup, Modal, ModalBody, Row } from "reactstrap"
import {
  createPreOrder,
  createReview,
  getRawUsers,
  updatePreOrder,
  updateReview,
} from "store/actions"
import Select from "react-select"

const PreOrderModal = ({ show, onCloseClick, updateData }) => {
  const dispatch = useDispatch()

  const { users, usersLoading } = useSelector(state => ({
    users: state.Users.rawUsers?.users,
    usersLoading: state.Users.loading,
  }))

  const [userSearchText, setUserSearchText] = useState("")
  const [selectedUserLabel, setSelectedUserLabel] = useState("Search user")
  const [user, setUser] = useState()
  const [select, setSelect] = useState("")

  const handleSelectChange = event => {
    const select = event.target.value
    setSelect(select)
  }
  const handleValidSubmit = (e, v) => {
    const data = {
      ...v,
      user,
      expectedPrice: parseFloat(v?.expectedPrice),
    }
    const updatedata = {
      ...v,
      user,
      expectedPrice: parseFloat(v?.expectedPrice),
      status: select,
    }
    if (updateData?._id) {
      dispatch(updatePreOrder(updatedata, updateData?._id, onCloseClick))
    } else {
      dispatch(createPreOrder(data, onCloseClick))
    }
  }

  useEffect(() => {
    dispatch(getRawUsers(userSearchText))
  }, [dispatch, userSearchText])

  const debounceUserSearch = debounce(value => setUserSearchText(value), 600)
  const handleUserEnters = textEntered => {
    debounceUserSearch(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedUserLabel(event.label)
    setUser(event?.value)
  }

  useEffect(() => {
    if (updateData) {
      setSelectedUserLabel(updateData?.phone)
      setUser(updateData?.user)
    }
  }, [updateData])

  const userOptions = [
    {
      options:
        users?.length &&
        users?.map((result, index) => ({
          key: index,
          label: `${result?.phone} ${result?.name ? "- " + result?.name : ""}`,
          value: result?._id,
          name: result?.name ? result?.name : "",
          phone: result?.phone,
        })),
    },
  ]

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="p-4">
        <CardTitle>{updateData ? "Edit" : "Add"} Preorder</CardTitle>
        <AvForm
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v)
          }}
        >
          <Row>
            <Col className="mt-3 ${updateData ?'col-6'  :'col-12' }">
              <p className="h4 mb-2 text-muted font-size-14">Select User</p>
              <FormGroup className="col-12">
                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                  <Select
                    onInputChange={handleUserEnters}
                    value={selectedUserLabel}
                    placeholder={selectedUserLabel}
                    onChange={handlerUserFinalValue}
                    options={userOptions}
                    classNamePrefix="select2-selection"
                    isLoading={usersLoading}
                  />
                </div>
              </FormGroup>
            </Col>
            {updateData && (
              <Col className="col-6 mb-3 mt-4 ">
                <p className="h4 mb-2 text-muted font-size-14">Select Status</p>

                <select
                  id="selectField"
                  className="form-control "
                  value={select}
                  onChange={handleSelectChange}
                >
                  <option value="new"> New</option>
                  <option value="processing">Processing</option>
                  <option value="closed">Closed</option>
                </select>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="col-6 mb-3">
              <AvField
                name="name"
                type="text"
                placeholder="Enter Name"
                value={updateData?.name}
              />
            </Col>

            <Col className="col-6 mb-3">
              <AvField
                name="phone"
                type="number"
                placeholder="Enter Phone"
                value={updateData?.phone}
                validate={{
                  minLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                  maxLength: {
                    value: 10,
                    errorMessage: "phone number must be 10 letter ",
                  },
                }}
              />
            </Col>

            <Col className="col-12 mb-3">
              <AvField
                name="expectedPrice"
                type="number"
                placeholder="Expected Price"
                value={updateData?.expectedPrice}
              />
            </Col>

            <Col lg={12} className="mb-3">
              <AvField
                name="requirement"
                type="textarea"
                placeholder="Requirement"
                value={updateData?.requirement}
              />
            </Col>

            <Col lg={12}>
              <AvField
                name="remarks"
                type="textarea"
                placeholder="Remarks"
                style={{
                  height: 80,
                }}
                value={updateData?.remarks}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end align-items-center">
            <div className="text-center mt-3">
              <button
                type="button"
                className="btn btn-danger ms-2"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-success ms-2">
                Save
              </button>
            </div>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

PreOrderModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  updateData: PropTypes.any,
}

export default PreOrderModal
