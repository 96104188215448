import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteUserAddress, getUserAddress } from "store/actions"
import PropTypes from "prop-types"
import { FaPen, FaTrash } from "react-icons/fa"
import { map } from "lodash"
import { AiOutlinePlus } from "react-icons/ai"

function UserAddress({
  userId,
  setSelectAddress,
  selectAddress,
  handleAddressModal,
}) {
  const dispatch = useDispatch()

  const { userAddress } = useSelector(state => ({
    userAddress: state.Users.userAddress.shippingAddresses,
  }))
  console.log(userAddress)
  useEffect(() => {
    if (userId) {
      dispatch(getUserAddress(userId))
    }
  }, [dispatch, userId])

  useEffect(() => {
    if (userAddress?.length >= 1) {
      setSelectAddress(userAddress[0])
    }
  }, [userAddress?.length])

  return (
    <>
      <div className="select-user">
        <div className="user-address">
          <p>Select Delivery Address</p>
          <div className="d-flex flex-wrap">
            {map(userAddress, (address, key) => (
              <div
                className={
                  "address-list me-3 mb-3 " +
                  `${selectAddress?._id === address?._id ? "isActive" : ""}`
                }
                key={key}
              >
                <h6 className="w-100" onClick={() => setSelectAddress(address)}>
                  Address {key + 1}
                </h6>
                <div
                  className="w-100"
                  onClick={() => setSelectAddress(address)}
                >
                  <span className="font-size-13" style={{ fontWeight: "500" }}>
                    {address?.shippingInfo?.name
                      ? address?.shippingInfo?.name
                      : address?.shippingInfo?.firstName}
                  </span>
                  <br />
                  <span className="font-size-12">
                    {address?.shippingInfo?.email}
                  </span>
                  <address className="font-size-12 mt-1 mb-0">
                    {address?.shippingInfo?.address}
                  </address>
                  <address className="font-size-12 mt-1 mb-0">
                    {address?.shippingInfo?.addressLine1}
                  </address>
                  <address className="font-size-12 mt-1 mb-0">
                    {address?.shippingInfo?.addressLine2}
                  </address>
                  <p className="mb-0 pb-0 font-size-12">
                    Pin Code -{" "}
                    <span style={{ fontWeight: "500" }}>
                      {address?.shippingInfo?.pinCode}
                    </span>
                  </p>
                  <p className="font-size-12 mb-0 pb-0">
                    Mobile -{" "}
                    <span style={{ fontWeight: "500" }}>
                      {address?.shippingInfo?.contactNumber}
                    </span>
                  </p>
                  <p className="font-size-12">
                    WhatsApp -{" "}
                    <span style={{ fontWeight: "500" }}>
                      {address?.shippingInfo?.whatsappNumber}
                    </span>
                  </p>
                  <p className="font-size-12">
                    {address?.shippingInfo?.landmark}
                    {address?.shippingInfo?.landmark && ","}{" "}
                    {address?.shippingInfo?.area}{" "}
                    {address?.shippingInfo?.area && ","}{" "}
                    {address?.shippingInfo?.street}{" "}
                    {address?.shippingInfo?.street && ","}{" "}
                    {address?.shippingInfo?.city}
                    {address?.shippingInfo?.city && ","}{" "}
                    {address?.shippingInfo?.state}{" "}
                    {address?.shippingInfo?.state && ","}{" "}
                    {address?.shippingInfo?.district}
                    {address?.shippingInfo?.district && ","}{" "}
                    {address?.shippingInfo?.country}
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <span
                    className="me-4 d-flex align-items-center remove"
                    onClick={() =>
                      dispatch(deleteUserAddress(address?._id, userId))
                    }
                  >
                    <FaTrash className="me-1 font-size-10" /> Remove
                  </span>
                  {/* <span
                    className="me-2 d-flex align-items-center remove"
                    onClick={() =>
                      dispatch(deleteUserAddress(address?._id, userId))
                    }
                  >
                    <FaTrash className="me-1 font-size-10" /> Remove
                  </span> */}
                  {/* <span
                    className="d-flex ml-2 align-items-center edit"
                    onClick={() => handleAddressModal(address)}
                 
                  >
                    <FaPen className="me-1 font-size-10" />
                    Edit
                  </span> */}
                </div>
                {selectAddress?._id === address?._id && (
                  <span className="corner">
                    <i className="bx bx-check" />
                  </span>
                )}
              </div>
            ))}

            <div className={"address-list dashed me-3 mb-3 "}>
              <div
                className="d-flex h-100 w-100 flex-column align-items-center justify-content-center"
                onClick={handleAddressModal}
              >
                <AiOutlinePlus className="font-size-24" />
                <p>Add New Address</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserAddress

UserAddress.propTypes = {
  userId: PropTypes.string,
  setSelectAddress: PropTypes.func,
  handleAddressModal: PropTypes.func,
  selectAddress: PropTypes.any,
}
