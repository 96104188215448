import { call, put, takeEvery } from "redux-saga/effects"

import { GET_TRANSACTIONS } from "./actionTypes"
import { getTransactionsFail, getTransactionsSuccess } from "./actions"

import { get, post, ApiPut, del } from "helpers/api_helper"
import { Notification } from "../../components/Common/Notification"

// Transaction - This line cannot be edited or removed
function getTransactionsAPi({ page, sort, limit, searchText, sortByDate }) {
  return get(
    `/order/admin/completed/all?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}&from=${sortByDate?.from}&to=${sortByDate?.to}`
  )
}

function* fetchTransactions({ payload }) {
  try {
    const response = yield call(getTransactionsAPi, payload)
    yield put(getTransactionsSuccess(response))
  } catch (error) {
    yield put(getTransactionsFail(error))
  }
}

function* transactionSaga() {
  yield takeEvery(GET_TRANSACTIONS, fetchTransactions)
}

export default transactionSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
