import React, { useEffect, useState } from "react"
import { Row, Col, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import useCloudinary from "hooks/useCloudinaryImage"

import { map, range } from "lodash"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

import "../../assets/scss/datatables.scss"
import { defaultImg } from "assets/images"

import moment from "moment"

import { getBidProducts } from "store/actions"
import PropTypes from "prop-types"

const ProductBidsTable = ({ singleProduct }) => {
  const dispatch = useDispatch()

  const { total, loading, bidProductDetails } = useSelector(state => ({
    total: state?.Bid?.bids?.total,
    loading: state?.Bid?.loading,
    bidProductDetails: state.Bid.bidProductDetails?.bids,
  }))

  const [page, setPage] = useState(1)
  const [totalItemLimit, setTotalItemLimit] = useState(10)

  useEffect(() => {
    dispatch(getBidProducts("", "", "", ""))
  }, [dispatch])

  const totalPages = Math.ceil(total / totalItemLimit)
  const pages = range(1, totalPages + 1)

  useEffect(() => {
    if (total) {
      setTotalItemLimit(total)
    }
  }, [total])
  console.log(singleProduct)
  const columns = [
    {
      dataField: "sl",
      text: "Sl",
      sort: true,
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
    },

    {
      dataField: "amount",
      text: "Amount",
      sort: true,
    },

    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
  ]

  const orderData = map(bidProductDetails, (item, index) => ({
    ...item,
    sl: <span>{(page - 1) * 10 + (index + 1)}</span>,
    createdAt: moment(item.createdAt).format("ll"),
    productName: (
      <div className="d-flex align-items-center">
        <img alt="product image" className="avatar-sm me-2" />
        <p className="p-0 m-0">{item?.product?.primaryLang?.name}</p>
      </div>
    ),
  }))

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setShopList(
      zshops.filter(zshop =>
        Object.keys(zshop).some(key =>
          zshop[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={orderData}
            search
            className=""
          >
            {toolkitProps => (
              <React.Fragment>
                {loading ? (
                  <Spinner color="secondary" className="d-block m-auto" />
                ) : (
                  <>
                    <Row>
                      <Col lg="6">
                        <div className="d-flex pb-4">
                          <div className="me-3">
                            <img
                              src={
                                singleProduct?.images &&
                                singleProduct?.images[0]
                                  ? useCloudinary(
                                      singleProduct?.images[0]?.url,
                                      "w_900"
                                    )
                                  : defaultImg
                              }
                              alt=""
                              className="avatar-md rounded-circle img-thumbnail"
                            />
                          </div>
                          <div className="flex-grow-1 align-self-center">
                            <div className="text-muted">
                              <p className="mb-2">{singleProduct?.itemCode}</p>
                              <h5 className="mb-1">{singleProduct?.price}</h5>
                              <p className="mb-0">{singleProduct?.bidAmount}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <p>
                          Total bids :&nbsp;
                          {singleProduct?.bidsCount
                            ? singleProduct?.bidsCount
                            : 0}{" "}
                        </p>
                      </Col>
                      {bidProductDetails?.length ? (
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              key={"_id"}
                              responsive
                              bordered={false}
                              striped={false}
                              classes={
                                "table align-middle table-nowrap table-striped ms-2 mb-2"
                              }
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                            />
                          </div>
                        </Col>
                      ) : (
                        <p className="my-2 mx-3 ">No bids!</p>
                      )}
                    </Row>

                    {/* <MyPagination
                      totalPages={pages}
                      page={page}
                      setPage={setPage}
                    /> */}
                  </>
                )}
              </React.Fragment>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ProductBidsTable
ProductBidsTable.propTypes = {
  singleProduct: PropTypes.any,
}
