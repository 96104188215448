import React, { useState } from "react"
import { Container } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import DatatableTables from "./DatatableTables"
import MetaTag from "components/Common/Meta-tag"
import BidProducts from "./BidProductDatatableTables"

function Biddings() {
  const [sort, setSort] = useState("allBiddings")

  const [singleProduct, setSingleProduct] = useState("")
  console.log(singleProduct)
  return (
    <>
      <MetaTag title={"All Biddings"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Biddings" />
        <Container fluid>
          <div className="d-flex align-items-center w-25 justify-content-center mx-2 mb-3">
            <input
              type="radio"
              className="btn-check"
              name="allBiddings"
              id={"allBiddings"}
              autoComplete="off"
              checked={sort === "allBiddings"}
              // onChange={() => setSort("allBiddings")}
              onChange={() => {
                setSort("allBiddings")
                setSingleProduct("")
              }}
            />
            <label
              className="btn btn-info d-flex align-items-center w-100"
              htmlFor={"allBiddings"}
              style={{
                whiteSpace: "nowrap",
              }}
            >
              All Biddings
            </label>
            <div style={{ width: "15px" }}></div>
            <input
              type="radio"
              className="btn-check"
              name="bidProduct"
              id={"bidProduct"}
              autoComplete="off"
              checked={sort === "bidProduct"}
              onChange={() => setSort("bidProduct")}
            />
            <label
              className="btn btn-info d-flex align-items-center w-100"
              htmlFor={"bidProduct"}
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Bid Products
            </label>
          </div>
          <div className="container-fluid">
            {sort === "allBiddings" ? (
              <DatatableTables />
            ) : (
              <BidProducts
                singleProduct={singleProduct}
                setSingleProduct={setSingleProduct}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

export default Biddings
