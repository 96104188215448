import {
  GET_BIDS,
  GET_BIDS_SUCCESS,
  GET_BIDS_FAIL,
  GET_BID_DETAILS,
  GET_BID_DETAILS_SUCCESS,
  GET_BID_DETAILS_FAIL,
  CREATE_BID,
  CREATE_BID_SUCCESS,
  CREATE_BID_FAIL,
  UPDATE_BID,
  UPDATE_BID_SUCCESS,
  UPDATE_BID_FAIL,
  DELETE_BID_SUCCESS,
  DELETE_BID_FAIL,
  DELETE_BID,
  GET_BIDPRODUCTS,
  GET_BIDPRODUCTS_SUCCESS,
  GET_BIDPRODUCTS_FAIL,
  DELETE_BIDPRODUCTS_SUCCESS,
  DELETE_BIDPRODUCTS_FAIL,
  DELETE_BIDPRODUCTS,
  CREATE_ORDER_FROM_BID,
  CREATE_ORDER_FROM_BID_SUCCESS,
  CREATE_ORDER_FROM_BID_FAIL,
} from "./actionTypes"

// Bid - This line cannot be edited or removed
export const getBids = (page, sort, limit, date, product, searchText) => ({
  type: GET_BIDS,
  payload: { page, sort, limit, date, product, searchText },
})

export const getBidsSuccess = bids => ({
  type: GET_BIDS_SUCCESS,
  payload: bids,
})

export const getBidsFail = error => ({
  type: GET_BIDS_FAIL,
  payload: error,
})

export const createBid = (bid, history) => ({
  type: CREATE_BID,
  payload: { bid, history },
})

export const createBidSuccess = bid => ({
  type: CREATE_BID_SUCCESS,
  payload: bid,
})

export const createBidFail = error => ({
  type: CREATE_BID_FAIL,
  payload: error,
})

export const getBidDetail = bidId => ({
  type: GET_BID_DETAILS,
  payload: bidId,
})

export const updateBid = (bid, bidId, history) => ({
  type: UPDATE_BID,
  payload: { bid, bidId, history },
})

export const updateBidSuccess = bid => ({
  type: UPDATE_BID_SUCCESS,
  payload: bid,
})

export const updateBidFail = error => ({
  type: UPDATE_BID_FAIL,
  payload: error,
})

export const deleteBid = (bidId, history) => ({
  type: DELETE_BID,
  payload: { bidId, history },
})

export const deleteBidSuccess = bid => ({
  type: DELETE_BID_SUCCESS,
  payload: bid,
})

export const deleteBidFail = error => ({
  type: DELETE_BID_FAIL,
  payload: error,
})

// BID PRODUCT

export const getBidProducts = (page, sort, limit, searchText) => ({
  type: GET_BIDPRODUCTS,
  payload: { page, sort, limit, searchText },
})

export const getBidProductsSuccess = bidProducts => ({
  type: GET_BIDPRODUCTS_SUCCESS,
  payload: bidProducts,
})

export const getBidProductsFail = error => ({
  type: GET_BIDPRODUCTS_FAIL,
  payload: error,
})
export const getBidDetails = bidId => ({
  type: GET_BID_DETAILS,
  payload: bidId,
})

export const getBidDetailsSuccess = bidDetails => ({
  type: GET_BID_DETAILS_SUCCESS,
  payload: bidDetails,
})

export const getBidDetailsFail = error => ({
  type: GET_BID_DETAILS_FAIL,
  payload: error,
})
// CONVERT to order
export const createOrderFromBid = (selectedItemId, order) => (
  console.log(selectedItemId),
  {
    type: CREATE_ORDER_FROM_BID,
    payload: { selectedItemId, order },
  }
)

export const createOrderFromBidSuccess = order => ({
  type: CREATE_ORDER_FROM_BID_SUCCESS,
  payload: order,
})

export const createOrderFromBidFail = error => ({
  type: CREATE_ORDER_FROM_BID_FAIL,
  payload: error,
})
